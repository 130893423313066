import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { faTwitterSquare, faInstagram, faRedditSquare } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsletterForm, { newsletterForm } from '../NewsletterForm/NewsletterForm';
import { STATUS_OPTED_IN, TYPE_TOP_10 } from 'src/shared/constants/newsletter';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { routes } from 'src/client/utils/router';
import { usePages } from 'src/client/helpers/usePages';
import { FooterBarModal } from 'src/client/containers/FooterBar/FooterBarModal';
import { FooterCategoryMenu } from './FooterCategoryMenu';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { FooterSeoSubHeadings } from './FooterSeoSubHeadings';

const Div = styled.div`
  position: relative;
  padding-top: 36px;
  min-height: 298px;
  background: ${(props) => props.theme.footerBarPrimary1};
  color: ${(props) => props.theme.footerBarPrimary2};

  .h4 {
    display: block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    text-transform: uppercase;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-size: 1rem;
    transition: background-color 0.3s;
    color: ${(props) => props.theme.footerBarPrimary3};
    border-bottom-color: ${(props) => props.theme.footerBarPrimary4};
    white-space: nowrap;

    @media (max-width: 767px) {
      border-bottom-width: 0;
      border-bottom-style: solid;
      border-top-width: 1px;
      border-top-style: solid;
      cursor: pointer;
      line-height: 14px;
      margin: 0;
      padding: 22px 24px;
      border-top-color: ${(props) => props.theme.footerBarPrimary4};

      &:last-child {
        border-bottom-color: ${(props) => props.theme.footerBarPrimary4};
      }
    }
  }

  li:nth-child(4) .h4 {
    @media (max-width: 767px) {
      border-bottom-width: 1px;
    }
  }

  .h4.dont-miss {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom-style: none;
  }

  .h4.active {
    @media (max-width: 767px) {
      background: ${(props) => props.theme.footerBarPrimary2};
      color: ${(props) => props.theme.footerBarPrimary5};
    }
  }

  ul {
    a {
      font-size: 0.9rem;
      text-decoration: none;
      display: block;
      padding: 0 0 10px;
      color: ${(props) => props.theme.footerBarPrimary2};
    }

    a:hover {
      color: ${(props) => props.theme.footerBarPrimary3};
    }

    a:not(.btn) {
      color: ${(props) => props.theme.footerBarPrimary2};
    }

    a:not(.btn):hover {
      color: ${(props) => props.theme.footerBarPrimary3};
    }

    li.standout {
      a:not(.btn) {
        color: ${(props) => props.theme.footerbarHighlight};
        &:hover {
          color: ${(props) => props.theme.footerBarPrimary3};
        }
      }
    }

    @media (max-width: 767px) {
      li {
        text-align: center;
        padding: 0;
      }

      .link {
        a {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          cursor: pointer;
          line-height: 14px;
          margin-bottom: 0;
          padding: 22px 24px;
          text-transform: uppercase;
          font-size: 1rem;
          border-bottom-color: ${(props) => props.theme.footerBarPrimary4};
          background: ${(props) => props.theme.footerBarPrimary5};
        }
      }

      .socials {
        text-align: center;
        margin-top: 15px;

        li {
          display: inline-block;
          margin: 0 10px;
        }

        a {
          font-size: 2.6rem;
        }

        span {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    min-height: 472px;
  }

  @media only screen and (max-width: 992px) {
    min-height: 472px;
  }

  @media only screen and (max-width: 768px) {
    min-height: 528px;
  }

  @media only screen and (max-width: 480px) {
    min-height: 550px;
  }

  .container {
    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .subscribe-btn {
    @media (max-width: 767px) {
      position: relative;
      max-width: 90%;
      left: 5%;
    }
  }

  .newsletter-text {
    font-size: 0.9rem;
    display: block;
    margin: 0 40px 18px 0;
    line-height: 24px;

    @media (max-width: 767px) {
      font-size: 0.85rem;
      margin: 0 0 15px;
      padding: 0 22px;
      line-height: 18px;
      text-align: center;
    }

    .highlight {
      padding-left: 4px;
      color: ${(props) => props.theme.footerBarPrimary3};
    }

    strong {
      color: ${(props) => props.theme.footerBarPrimary3};
    }
  }

  .highlight {
    color: ${(props) => props.theme.footerBarPrimary6};
  }

  .compliance {
    clear: both;
    padding-top: 20px;
    margin: 0 auto;
    text-align: center;
    font-size: 0.9rem;
    line-height: 20px;

    @media (max-width: 767px) {
      padding: 10px;
      font-size: 0.7rem;
      line-height: 14px;
    }

    a {
      text-decoration: none;
      color: inherit;

      :not(.footer-link) {
        color: ${(props) => props.theme.footerBarPrimary3};
      }

      &.footer-link:hover {
        color: ${(props) => props.theme.footerBarPrimary3};
      }
    }
  }

  .newsletter-container {
    @media (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media (max-width: 767px) {
    .ani-sub-expand-enter {
      max-height: 0;
      overflow: hidden;
    }

    .ani-sub-expand-enter.ani-sub-expand-enter-active {
      max-height: 350px;
      transition: max-height 500ms ease-in;
    }

    .ani-sub-expand-exit {
      max-height: 350px;
    }

    .ani-sub-expand-exit.ani-sub-expand-exit-active {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease-in;
    }
  }

  .classic-site-notice {
    display: block;
    text-align: center;
    background-color: #43a511;
    padding: 5px;
    font-size: 0.85rem;
    color: #ffffff;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  & .more-info-button {
    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: ${(props) => props.theme.footerBarPrimary3};
    }
  }
`;

export default function FooterBar() {
  const year = new Date().getFullYear();
  const user = useSelector((state) => state.auth.user);
  const siteDomain = useSelector((state) => state.site.domain);
  const newsletterStatus = useSelector((state) => state.newsletter.status);
  const submitted = useSelector((state) => state.newsletter.submitted);
  const { ssoUrl, version: appVersion, config, location } = useSelector((state) => state.app);

  const isCalifornia = location?.country === 'US' && location?.state === 'CA';

  const [openedCategories, setOpenCategories] = useState<string[]>([]);
  const path2257 = usePages('/2257');

  const [infoModal, setInfoModal] = useState<boolean>(false);

  const showWeeklyTopModels = config.footer.hasWeeklyTopModels;
  const showLatestUpdates = config.footer.hasLatestUpdates;
  const showActorsWanted = config.footer.hasActorsWanted;
  const showPhotographersWanted = config.footer.hasPhotographersWanted;
  const showDirectorsWanted = config.footer.hasDirectoresWanted;
  const showCommunityGuidelines = config.footer.hasCommunityGuidelines;
  const showPhilosophy = config.footer.hasPhilosophy;
  const showReportAbuselink = config.footer.hasReportAbuselink;

  const { hasAccountCenter, shop } = config;

  const { customerSupportUrl, webmastersUrl, camsUrl, customCompliance, termsUrl, privacyUrl } = config.footer;

  const isWhiteLabel = config.isWhiteLabel;

  return (
    <Div>
      <FooterBarModal onHide={() => setInfoModal(!infoModal)} privacyUrl={privacyUrl} show={infoModal} />
      <div className="container">
        <ul className="list-unstyled">
          {!(newsletterStatus === STATUS_OPTED_IN && !submitted) && showWeeklyTopModels && (
            <li className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div>
                <span className="h4 text-uppercase hidden-xs">
                  {isWhiteLabel ? (
                    <>Get free content in your email</>
                  ) : (
                    <>
                      <strong className="highlight">Free</strong> Weekly Top Ten Models
                    </>
                  )}
                </span>
                <span className="h4 visible-xs dont-miss">Don&#39;t Miss Out</span>
                <span className="newsletter-text">
                  {isWhiteLabel ? (
                    <>Get our newsletter and receive free content right to your inbox!</>
                  ) : (
                    <>
                      <strong>Receive</strong> the <strong>Weekly Top 10 Models</strong> directly to your{' '}
                      <strong>inbox</strong>. You'll also get <strong>exclusive deals</strong> only available through
                      our newsletters.
                    </>
                  )}
                  <br />
                  <div className="more-info-button" onClick={() => setInfoModal(!infoModal)}>
                    <StyledIcon $spaceRight icon={faInfoCircle} />
                    <span>More Information</span>
                  </div>
                </span>
                <NewsletterForm
                  newsletterType={TYPE_TOP_10}
                  submitBtnClassName="btn btn-primary subscribe-btn"
                  form={newsletterForm}
                />
                <div className="hidden-lg v-offset-35" />
                <div className="clear" />
              </div>
            </li>
          )}

          <FooterCategoryMenu
            openedCategories={openedCategories}
            setOpenCategories={setOpenCategories}
            id="amazing"
            label="Amazing Content"
            links={[
              ...(showLatestUpdates
                ? [
                    {
                      to: '/updates',
                      label: 'Latest Updates',
                    },
                  ]
                : []),
              {
                to: '/erotic-films',
                label: 'Original Films',
              },
              {
                to: '/galleries',
                label: 'High Resolution Photos',
              },
              {
                to: '/models',
                label: 'Exclusive Models',
              },
              ...(config.galleries.botd.active
                ? [
                    {
                      to: '/photo-archive',
                      label: 'Beauty of the Day',
                    },
                  ]
                : []),
              {
                to: camsUrl,
                openNewWindow: true,
                label: 'Live Cams',
              },
              ...(shop
                ? [
                    {
                      to: shop.domain,
                      openNewWindow: true,
                      label: shop.name,
                    },
                  ]
                : []),
              ...(config.footer.aboutUsUrl
                ? [
                    {
                      to: config.footer.aboutUsUrl,
                      label: 'About Us',
                    },
                  ]
                : []),
            ]}
          />

          <FooterCategoryMenu
            openedCategories={openedCategories}
            setOpenCategories={setOpenCategories}
            id="affiliate"
            label="Become Affiliated"
            links={[
              {
                to: webmastersUrl,
                isExternal: true,
                label: 'Webmasters',
              },
              ...(showActorsWanted
                ? [
                    {
                      to: 'https://help.metart.network/artists-models/become-a-model/model-submission-guidelines',
                      isExternal: true,
                      label: 'Models Apply Here',
                      className: 'standout',
                    },
                  ]
                : []),
              ...(showPhotographersWanted
                ? [
                    {
                      to:
                        'https://help.metart.network/artists-models/become-a-photographer/photographer-technical-guidelines',
                      isExternal: true,
                      label: 'Photographers Wanted',
                    },
                  ]
                : []),
              ...(showDirectorsWanted
                ? [
                    {
                      to: 'https://help.metart.network/artists-models/become-a-director/director-technical-guidelines',
                      isExternal: true,
                      label: 'Directors Wanted',
                    },
                  ]
                : []),
              ...(showPhilosophy
                ? [
                    {
                      to: 'https://help.metart.network/welcome/company-philosophy',
                      isExternal: true,
                      label: 'Philosophy',
                    },
                  ]
                : []),
              ...(showCommunityGuidelines
                ? [
                    {
                      to: ' https://help.metart.network/policies-rules/community-guidelines',
                      isExternal: true,
                      label: 'Community Guidelines',
                    },
                  ]
                : []),
              {
                to: routes.report.routes.abuse.path,
                label: 'Report Abuse',
              },
            ]}
          />

          <FooterCategoryMenu
            openedCategories={openedCategories}
            setOpenCategories={setOpenCategories}
            id="service"
            label="Account & Services"
            links={[
              ...(!user
                ? [
                    {
                      to: ssoUrl,
                      isExternal: true,
                      label: 'Member Login',
                    },
                    {
                      to: `${ssoUrl}/forgot-password`,
                      isExternal: true,
                      label: 'Lost Password',
                    },
                  ]
                : []),
              ...(user && hasAccountCenter
                ? [
                    {
                      to: ssoUrl,
                      isExternal: true,
                      openNewWindow: true,
                      label: 'Manage Account',
                    },
                  ]
                : []),
              {
                to: customerSupportUrl,
                isExternal: true,
                label: 'Customer Support',
              },
              {
                to: termsUrl,
                isExternal: true,
                label: 'Terms & Conditions',
              },
              {
                to: privacyUrl,
                isExternal: true,
                label: 'Privacy',
              },
              ...(showReportAbuselink
                ? [
                    {
                      to: '/piracy',
                      label: 'Report Piracy',
                    },
                  ]
                : []),
            ]}
          />

          <li className="col-lg-2 col-md-3 col-sm-3 col-xs-12 socials">
            <span className="h4 hidden-xs">Social</span>
            <ul className="list-unstyled">
              {config.twitter && (
                <li>
                  <a
                    aria-label="Twitter"
                    href={`https://twitter.com/${config.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitterSquare} /> <span>Twitter</span>
                  </a>
                </li>
              )}
              {config.instagram && (
                <li>
                  <a
                    aria-label="Instagram"
                    href={`https://www.instagram.com/${config.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} /> <span>Instagram</span>
                  </a>
                </li>
              )}
              {config.reddit && (
                <li>
                  <a
                    aria-label="Reddit"
                    href={`https://www.reddit.com/r/${config.reddit}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faRedditSquare} /> <span>Reddit</span>
                  </a>
                </li>
              )}
              {config.hasBlog && config.featureFlags?.enableSeoCategoriesMenu && (
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
              )}
            </ul>
          </li>
        </ul>

        <div className="compliance">
          <FooterSeoSubHeadings />
          <p>
            <NavLink to={path2257}>18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement.</NavLink>
            <br className="visible-xs" />
            <br />
            {isCalifornia && (
              <>
                <a
                  href="https://help.metart.network/policies-rules/privacy-policy#PrivacyPolicy-UpdatingorDeletingYourPersonalInformation"
                  target="_blank"
                >
                  Do Not Sell or Share My Personal Information
                </a>
                <br className="visible-xs" />
                <br />
              </>
            )}
            Warning - This site contains adult material of a sexual nature - you must be of legal age in your area to
            view this material. <br />
            All materials on {siteDomain}, are copyright © {year} {siteDomain}. All models were at least 18 years of age
            when photographed. <br />
            Epoch is an authorized sales agent, their billing support may be found at{' '}
            <a href="https://epoch.com" target="_blank" rel="noopener noreferrer">
              Epoch.com
            </a>
            .
            {customCompliance && (
              <>
                <br />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: customCompliance }} />
              </>
            )}
            <br />
            {appVersion}
          </p>
        </div>
      </div>
      {user && user.isClassicAccessible && (
        <a href="https://members.met-art.com/members/?legacy=1" className="classic-site-notice">
          Click here to use legacy version
        </a>
      )}
    </Div>
  );
}
