import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { RESET_DURATION } from './helpers/config';
import { load as loadPlayedMovies } from './playedMovies';
import { SORT_TYPES } from 'src/shared/constants/movies';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { Gallery } from 'src/@types/Gallery';

const LOAD = 'man-site/movies/LOAD';
const LOAD_SAGA = 'man-site/movies/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/movies/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/movies/LOAD_FAIL';

const endpoint = constructUrl(urls.get.movies);
const ppsEndpoint = constructUrl(urls.get.PPS);

type CallParams = {
  direction?: string;
  endDate?: string;
  first?: number;
  page?: number;
  startDate?: string;
  type?: string;
  sortBy?: string;
  PPSOnly?: boolean;
};

export type State = {
  error?: SuperAgentError;
  galleries: Gallery[];
  itemCount: number;
  lastCallParams: CallParams;
  loaded: boolean;
  loading: boolean;
  pageSize: number;
  timestamp: Number;
};

const initialState: State = {
  error: undefined,
  galleries: [],
  itemCount: 0,
  lastCallParams: {},
  loaded: false,
  loading: false,
  pageSize: 60,
  timestamp: new Date().getTime(),
};

type Load = {
  type: typeof LOAD;
  params: CallParams;
  lastCallParams: CallParams;
};

type LoadSuccess = {
  type: typeof LOAD_SUCCESS;
  result: {
    galleries: Gallery[];
    total: number;
  };
};

type LoadFail = {
  type: typeof LOAD_FAIL;
  error: SuperAgentError;
};

type Action = Load | LoadSuccess | LoadFail;

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        lastCallParams,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        galleries: action.result.galleries,
        itemCount: action.result.total,
        timestamp: new Date().getTime(),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams: CallParams, params: CallParams) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params: CallParams) {
  const currentState = (yield select()).movies;
  const { lastCallParams } = currentState;
  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;
  if (
    ((!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) ||
    (!currentState.loaded && !currentState.loading && !!currentState.error)
  ) {
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);
    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order,
            direction: sortType.direction,
          }
        : {}),
      type: 'MOVIE',
    };

    delete finalParams.sortBy;

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) => client.get(params.PPSOnly ? ppsEndpoint : endpoint, { params: finalParams }),
      { lastCallParams: { ...params } }
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.movies.galleries.length > 0) {
    const objectUUIDs = newState.movies.galleries.map((item) => item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      })
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      })
    );
    yield put(
      loadPlayedMovies({
        galleryUUIDs: objectUUIDs,
      })
    );
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
