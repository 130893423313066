import React from 'react';
import styled from 'styled-components/macro';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';

export const PromoHeaderRibbon = () => {
  const [joinHref] = useJoinUrl({ campaign: 'ad-zone-header-ribbon' });

  return (
    <PromoHeaderRibbonContainer>
      <PromoHeaderRibbonLink href={joinHref}>
        <span>🇺🇸 FLASH SALE, 67% OFF!!!! - July 4th special - HURRY!!!! Sale ends July 8th</span>
      </PromoHeaderRibbonLink>
    </PromoHeaderRibbonContainer>
  );
};

const PromoHeaderRibbonContainer = styled.div`
  align-items: center;
  background-color: #e71c1c;
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
  padding: 4px 0;
  text-align: center;
`;

const PromoHeaderRibbonLink = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }

  > span {
    display: block;
    margin: 0 2px;
    text-align: center;
  }
`;
