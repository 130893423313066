import { take, call, fork, select, put } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { generatePurchaseProduct } from 'src/shared/converters';
import { BillerById } from 'src/shared/constants/billers';

const LOAD = 'man-site/PPSInfo/LOAD';
const LOAD_SAGA = 'man-site/PPSInfo/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/PPSInfo/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/PPSInfo/LOAD_FAIL';
const PURCHASE = 'man-site/PPSInfo/PURCHASE';
const PURCHASE_SAGA = 'man-site/PPSInfo/PURCHASE_SAGA';
const PURCHASE_SUCCESS = 'man-site/PPSInfo/PURCHASE_SUCCESS';
const PURCHASE_FAIL = 'man-site/PPSInfo/PURCHASE_FAIL';
const VALIDATE = 'man-site/PPSInfo/VALIDATE';
const VALIDATE_SAGA = 'man-site/PPSInfo/VALIDATE_SAGA';
const VALIDATE_SUCCESS = 'man-site/PPSInfo/VALIDATE_SUCCESS';
const VALIDATE_FAIL = 'man-site/PPSInfo/VALIDATE_FAIL';

const endpoint = constructUrl(urls.get.PPS);

interface CartItem {
  iframeURL: string;
  redirectURL: string;
}

interface Invoice {
  amount: string;
  currency: string;
  productUUID: string;
  txnID: string;
  billerID: number;
  vatTax: string;
  siteUUIDs: string[];
  initialPeriod: number;
  recurringPeriod: number;
  isPPS: boolean;
}

interface InitialState {
  result: {};
  loading?: boolean;
  loaded?: boolean;
  purchaseLoading?: boolean;
  purchaseStatus?: boolean;
  purchaseError?: ResponseError;
  purchaseRedirect?: string;
  cartItem?: CartItem;
  redirectURL?: string;
  subscriptionID?: string;
  validating?: boolean;
  invoices?: Invoice[];
}

const initialState: InitialState = {
  result: {},
};

const urlResult2Decorator = (result) => ({
  gallery: result.edges.reduce(
    (r, gallery) => ({
      ...r,
      [gallery.UUID]: true,
    }),
    {} as {
      [key: string]: boolean;
    }
  ),
});

interface ActionLoad {
  type: typeof LOAD;
}
interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: {
    edges: {
      UUID: string;
    }[];
  };
}
interface ActionLoadFail {
  type: typeof LOAD_FAIL;
}
interface ActionPurchase {
  type: typeof PURCHASE;
}
interface ActionPurchaseSuccess {
  type: typeof PURCHASE_SUCCESS;
  result: {
    redirectURL: string;
    cartItem: CartItem;
    subscriptionID: string;
  };
}
interface ActionPurchaseFail {
  type: typeof PURCHASE_FAIL;
  error: ResponseError;
}

interface ActionValidate {
  type: typeof VALIDATE;
}
interface ActionValidateSuccess {
  type: typeof VALIDATE_SUCCESS;
  result: {
    invoices: Invoice[];
  };
}
interface ActionValidateFail {
  type: typeof VALIDATE_FAIL;
}

export default function reducer(
  state = initialState,
  action:
    | ActionLoad
    | ActionLoadSuccess
    | ActionLoadFail
    | ActionPurchase
    | ActionPurchaseSuccess
    | ActionPurchaseFail
    | ActionValidate
    | ActionValidateSuccess
    | ActionValidateFail
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        result: urlResult2Decorator(action.result),
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case PURCHASE:
      return {
        ...state,
        purchaseLoading: true,
        purchaseError: undefined,
        purchaseStatus: false,
        purchaseRedirect: undefined,
      };
    case PURCHASE_SUCCESS: {
      const { redirectURL, cartItem, subscriptionID } = action.result;
      return {
        ...state,
        purchaseLoading: false,
        purchaseStatus: !redirectURL,
        purchaseRedirect: redirectURL,
        cartItem,
        subscriptionID,
      };
    }
    case PURCHASE_FAIL:
      return {
        ...state,
        purchaseLoading: false,
        purchaseStatus: false,
        purchaseError: action.error?.response?.body || action.error?.response?.text,
      };
    case VALIDATE:
      return {
        ...state,
        validating: true,
      };
    case VALIDATE_SUCCESS:
      return {
        ...state,
        validating: false,
        invoices: action.result.invoices,
      };
    case VALIDATE_FAIL:
      return {
        ...state,
        validating: false,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function purchase(params) {
  return {
    type: PURCHASE_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.PPSInfo;
  const currentState = yield select(getState);
  if (!currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) =>
      client.get(endpoint, {
        params: {
          galleryUUIDs: params.gallery,
        },
      })
    );
    yield call(loadFunc);
  }
}

function* purchaseGenerator(params) {
  const { affiliateCode, galleryName, galleryUUID, isStaffSelection, quality, siteName, siteUUID, type } = params;
  const getState = (state) => state.PPSInfo;
  const currentState = yield select(getState);

  if (!currentState.purchaseLoading) {
    const product = generatePurchaseProduct(galleryUUID, quality, siteUUID);

    const itemName = galleryName;
    const itemBrand = isStaffSelection ? 'Staff Selection' : siteName;
    const itemType = type === 'movie' ? 'Films' : 'Galleries';
    const objectUUID = product?.objectUUID;

    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        affiliate_id: affiliateCode,
        source: 'PPS Dialog',
        value: product?.initialPrice,
        tax: '',
        currency: product?.currency,
        cbp: 1,
        items: [
          {
            item_name: itemName,
            item_brand: itemBrand,
            item_category: 'pps',
            item_category2: quality,
            item_list_name: itemType,
            item_list_id: objectUUID,
            quantity: 1,
            price: product?.initialPrice,
          },
        ],
      },
    });
    window.dataLayer.push({
      event: 'begin_checkout_ua',
      ecommerce: {
        currency: product?.currency,
        checkout: {
          actionField: {
            affiliate_id: affiliateCode,
            source: 'PPS Dialog',
            value: product?.initialPrice,
            tax: '',
            cbp: 1,
            category2: quality,
          },
          products: [
            {
              name: itemName,
              brand: itemBrand,
              category: 'pps',
              variant: itemType,
              id: objectUUID,
              quantity: 1,
              price: product?.initialPrice,
            },
          ],
        },
      },
    });

    const purchaseFunc = getRequestFunc([PURCHASE, PURCHASE_SUCCESS, PURCHASE_FAIL], (client) =>
      client.post(constructUrl(urls.post.cbpPurchase).replace(':galleryUUID', galleryUUID).replace(':quality', quality))
    );

    const { result } = yield call(purchaseFunc);

    if (result?.cartItem) {
      const { cartItemUUID, iframeURL, redirectURL } = result.cartItem;
      const extSubscriptionID = result.subscriptionID;

      if (result.status && !result.redirectURL) {
        yield put({
          type: VALIDATE_SAGA,
          params: {
            cartItemUUID,
            extSubscriptionID,
            itemBrand,
            itemName,
            itemType,
            objectUUID,
            quality,
          },
        });
        return;
      }

      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          transaction_id: cartItemUUID,
          affiliate_id: affiliateCode,
          source: 'PPS Dialog',
          value: result.cartItem.product.initialPrice,
          tax: '',
          currency: result.cartItem.product.currency,
          cbp: 0,
          items: [
            {
              item_name: itemName,
              item_id: result.cartItem.product.productUUID,
              item_brand: itemBrand,
              item_category: 'pps',
              item_category2: quality,
              item_list_name: itemType,
              item_list_id: objectUUID,
              quantity: 1,
              price: result.cartItem.product.initialPrice,
            },
          ],
        },
      });
      window.dataLayer.push({
        event: 'begin_checkout_ua',
        ecommerce: {
          currency: result.cartItem.product.currency,
          checkout: {
            id: cartItemUUID,
            actionField: {
              affiliate_id: affiliateCode,
              source: 'PPS Dialog',
              value: result.cartItem.product.initialPrice,
              tax: '',
              cbp: 0,
              product_uuid: result.cartItem.product.productUUID,
              category2: quality,
            },
            products: [
              {
                name: itemName,
                id: objectUUID,
                brand: itemBrand,
                category: 'pps',
                variant: itemType,
                quantity: 1,
                price: result.cartItem.product.initialPrice,
              },
            ],
          },
        },
      });

      window.location.href = `${result.redirectURL}/?cartItemUUID=${cartItemUUID}&${
        iframeURL ? `iframeURL=${encodeURIComponent(iframeURL)}` : `redirectURL=${encodeURIComponent(redirectURL)}`
      }`;
    }
  }
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* validateGenerator(params) {
  const { cartItemUUID, extSubscriptionID, itemBrand, itemName, itemType, objectUUID, quality } = params;

  // start validating even though we haven't made the call yet
  yield put({ type: VALIDATE });

  // wait 5 secs to allow billing to receive postback
  yield delay(5000);

  const validateFunc = getRequestFunc([VALIDATE, VALIDATE_SUCCESS, VALIDATE_FAIL], (client) =>
    client.get(constructUrl(urls.get.invoice), {
      params: {
        cartItemUUID,
        extSubscriptionID,
        scriptLoaded: !!window.VWO,
      },
    })
  );

  const { result } = yield call(validateFunc);

  if (result?.invoices) {
    const { invoices } = result;

    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: cartItemUUID,
        source: 'PPS Dialog',
        value: invoices.reduce((acc, val) => acc + parseFloat(val.amount), 0),
        tax: invoices.reduce((acc, val) => acc + parseFloat(val.vatTax), 0),
        currency: invoices[0].currency,
        payment_method: BillerById[invoices[0].billerID],
        cbp: 1,
        items: invoices.map((item) => {
          const finalQuality = quality
            ? `${quality.charAt(0).toUpperCase()}${quality.slice(1)}`
            : 'No Quality Provided';

          return {
            item_name: itemName,
            item_id: item.productUUID,
            item_brand: itemBrand,
            item_category: 'pps',
            item_category2: finalQuality,
            item_list_name: itemType,
            item_list_id: objectUUID,
            quantity: 1,
            price: parseFloat(item.amount),
          };
        }),
      },
    });

    window.dataLayer.push({
      event: 'purchase_ua',
      ecommerce: {
        currency: invoices[0].currency,
        purchase: {
          actionField: {
            id: cartItemUUID,
            source: 'PPS Dialog',
            revenue: invoices.reduce((acc, val) => acc + parseFloat(val.amount), 0),
            tax: invoices.reduce((acc, val) => acc + parseFloat(val.vatTax), 0),
            option: BillerById[invoices[0].billerID],
            cbp: 1,
          },
          products: invoices.map((item) => {
            const finalQuality = quality
              ? `${quality.charAt(0).toUpperCase()}${quality.slice(1)}`
              : 'No Quality Provided';

            return {
              name: itemName,
              product_uuid: item.productUUID,
              brand: itemBrand,
              category: 'pps',
              category2: finalQuality,
              variant: itemType,
              id: objectUUID,
              quantity: 1,
              price: parseFloat(item.amount),
            };
          }),
        },
      },
    });
  }

  setTimeout(() => window.location.reload(), 7000);
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

function* watchPurchase() {
  while (true) {
    const { params } = yield take(PURCHASE_SAGA);
    yield fork(purchaseGenerator, params);
  }
}

function* watchValidate() {
  while (true) {
    const { params } = yield take(VALIDATE_SAGA);
    yield fork(validateGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchPurchase), fork(watchValidate)];
