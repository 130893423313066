import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

import SortMenu from '../Cards/SortMenuPlaceholder';
import GalleriesCardPlaceholder from '../Cards/GalleriesCardPlaceholder';
import PaginationPlaceholder from '../Cards/PaginationPlaceholder';

type Props = {
  detailed?: boolean;
  count?: number;
  loading?: boolean;
  children?: ReactNode;
};

export const GalleriesPlaceholder = (props: Props) => {
  const { loading, children, detailed, count = 30 } = props;

  // Not just !loading, but explicitly set to false by the caller.
  if (loading === false) {
    return <>{children}</>;
  }

  return (
    <div className="placeholder-wrap">
      <SortMenu />
      <CardsWrap>
        {[...Array(count)].map((elem, i) => (
          <GalleriesCardPlaceholder detailed={detailed} key={i} />
        ))}
      </CardsWrap>
      <PaginationPlaceholder />
    </div>
  );
};

const CardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default GalleriesPlaceholder;
