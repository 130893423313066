import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Panel, Badges, Badge } from '@tovia/man-ui';
import RatingSlider from '@tovia/man-ui/lib/components/RatingSlider/RatingSlider';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faEye, faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { faComments, faMicrophoneAlt } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import PanelHeadline from '@tovia/man-ui/lib/components/Panel/PanelHeadline';
import { formatViews } from 'src/client/utils/converters';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { show as showShareDialogFn } from 'src/client/redux/modules/shareDialog';
import { Panel as ActivityPanelPlaceholder } from 'src/client/components/Placeholders/Panels/Panel';
import { scrollTo } from 'src/client/helpers';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';

const PanelWrapper = styled.div`
  .rating-slider {
    top: 32px;
    margin: 0 45px;
    max-width: 238px;
    margin-bottom: 43px;
  }

  .rating {
    margin-right: 10px;
  }

  .rating-average {
    width: 48px;
    height: 48px;
    line-height: 42px;
    border-width: 3px;
    border-style: solid;
    border-color: ${(props) => props.theme.primaryBtn1Bg};
    border-radius: 50%;
    display: block;
    //font-size: 1rem;
    text-align: center;
  }

  .rating-header {
    display: block;
    font-size: 1.2rem;
    margin-top: 3px;
  }

  .rating-subheader {
    display: block;
    font-size: 0.75rem;
    color: ${(props) => props.theme.primary7};
  }

  .favorite-badge {
    &.favorited {
      path {
        fill: #a80000;
      }
    }
  }
`;

type Props = {
  fullMode?: boolean;
  ratingAverage: number;
  ratings: number;
  commentCount?: number;
  favoriteCount?: number;
  onToggleFavor?: () => void;
  isFavorited?: boolean;
  views: number;
  itemUrl: string;
  objectUUID: string;
  objectType: string;
  blogUrl?: string | null;
  className?: string;
};

export const ActivityPanel = (props: Props) => {
  const dispatch = useDispatch();

  const ratingInfo = useSelector((state) => state.ratingInfo);
  const comments = useSelector((state) => state.comments.comments);
  const user = useSelector((state) => state.auth.user);
  const intl = useIntl();
  const generateJoinUrl = useJoinUrlGenerator();

  const {
    blogUrl = '',
    className = '',
    commentCount = 0,
    favoriteCount = null,
    fullMode = false,
    isFavorited,
    itemUrl,
    objectType,
    objectUUID,
    onToggleFavor = () => {},
    ratingAverage,
    ratings,
    views,
  } = props;

  const showShareDialog = (link) => dispatch(showShareDialogFn(link, `activity-panel-${objectType}`, objectUUID));
  const finalCommentsCount = comments[objectUUID] ? comments[objectUUID].commentsCount : commentCount;

  const onRate = useCallback(
    (rating) => {
      dispatch(rateItem({ objectUUID, objectType: objectType.toUpperCase(), rating }));
    },
    [objectType, objectUUID, dispatch],
  );

  const userRating = useMemo(() => ratingInfo.ratings[objectUUID], [ratingInfo.ratings, objectUUID]);

  const activityPanelContent = (
    <Panel className="top-panel">
      <PanelHeadline>{intl.formatMessage({ id: 'activityPanel.headline', defaultMessage: 'Activity' })}</PanelHeadline>
      <div className="pull-left rating">
        <span className="rating-average">{ratingAverage || 'N/A'}</span>
      </div>
      <div className="pull-left">
        <span className="rating-header">
          {intl.formatMessage({ id: 'activityPanel.memberRating', defaultMessage: 'Member Rating' })}
        </span>
        <span className="rating-subheader">
          {ratingAverage > 0 && (
            <span>
              {intl.formatMessage(
                { id: 'activityPanel.basedOnRatings', defaultMessage: `based on ${formatViews(ratings)} ratings` },
                { ratings: formatViews(ratings) },
              )}
            </span>
          )}
          {!ratingAverage && (
            <span>
              {intl.formatMessage({ id: 'activityPanel.beTheFirstToRate', defaultMessage: 'Be the first to rate!' })}
            </span>
          )}
        </span>
      </div>
      <div className="clear" />
      <RatingSlider
        className="rating-slider"
        submitRating={user && fullMode ? onRate : () => redirectToJoin(generateJoinUrl, 'rate-activity-panel')}
        userRating={userRating}
      />
      <Badges>
        {favoriteCount !== null && (
          <Badge
            className={`favorite-badge ${fullMode && isFavorited ? ' favorited' : ''}`}
            onClick={onToggleFavor}
            icon={<FontAwesomeIcon icon={isFavorited ? faHeartSolid : faHeart} />}
            text={favoriteCount || 0}
          />
        )}
        <Badge text={formatViews(views)} icon={<FontAwesomeIcon icon={faEye} />} />
        {finalCommentsCount > 0 && (
          <Badge
            text={formatViews(finalCommentsCount)}
            icon={<FontAwesomeIcon icon={faComments} />}
            onClick={() => scrollTo('comment-section')}
          />
        )}
        {blogUrl && (
          <Link to={blogUrl}>
            <Badge
              text={intl.formatMessage({ id: 'activityPanel.interview', defaultMessage: 'Interview' })}
              icon={<FontAwesomeIcon icon={faMicrophoneAlt} />}
            />
          </Link>
        )}
        <a className="clickable share-button" onClick={() => showShareDialog(itemUrl)}>
          <Badge
            text={intl.formatMessage({ id: 'activityPanel.share', defaultMessage: 'Share' })}
            icon={<FontAwesomeIcon icon={faShare} />}
          />
        </a>
      </Badges>
    </Panel>
  );

  const activityPanelMarkup =
    !user || ratingInfo.loaded ? activityPanelContent : <ActivityPanelPlaceholder width={100} />;

  return <PanelWrapper className={className}>{activityPanelMarkup}</PanelWrapper>;
};
