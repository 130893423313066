import React, { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from '../../redux';
import { Container, ImageButton, MobileMenuButton, HeaderBarWrapper, NavbarIcon } from './styles';
import cx from 'classnames';
import { useUpgradeStrip } from './helpers/useUpgradeStrip';
import { UpgradeStrip } from './UpgradeStrip';
import HeaderStrip from '@tovia/man-ui/lib/components/HeaderStrip/HeaderStrip';
import styled from 'styled-components/macro';
import { PopoverSitePicker } from './PopoverSitePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { MobileMenuDrawer } from './MobileMenu/MobileMenuDrawer';
import { NavLink, useHistory } from 'react-router-dom';
import { LiveGirlsMenu } from '../../components/LiveGirls/LiveGirlsMenu';
import { FormattedMessage } from 'react-intl';
import { MoreGirls } from './MoreGirls';
import Tooltip from '@tovia/man-ui/lib/components/Tooltip/Tooltip';
import { UserMenuItems } from './UserMenuItems';
import { InternalLinks } from './InternalLinks';
import SearchBoxContainer from '../SearchBox/SearchBox';
import { RecentsBox } from './RecentsBox/RecentsBox';
import SiteNotificationsPanel from '../SiteNotifications/SiteNotifications';
import { SubmissionError } from 'redux-form';
import { HeaderContext } from './HeaderProvider';
import { TABLET_LANDSCAPE } from '../../constants/mediaQueries';
import { useInfinityTagEvent } from '../../helpers/useInfinityTagEvents';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import { PromoHeaderRibbon } from 'src/client/containers/Promo/PromoHeaderRibbon';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { PartnerHeaderBanner } from 'src/client/components/PartnerBanners/PartnerHeaderBanner';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { SeoCategoriesMenu } from '../../components/SeoCategoriesMenu/SeoCategoriesMenu';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

export const HeaderBar = () => {
  const user = useSelector((state) => state.auth.user);
  const site = useSelector((state) => state.site);
  const config = useSelector((state) => state.app.config);
  const cdnUrl = useSelector((state) => state.app.cdnUrl);
  const history = useHistory();
  const sendGetStartedEventOnClick = useInfinityTagEvent({
    name: 'Get stared button',
    type: 'Inquiry',
    value: '0.001',
  });

  const mainHeader = useRef<HTMLDivElement>(null);
  const searchButtonDisabledRef = useRef(false);

  const [upgradeStripOpen, closeUpgradeStrip] = useUpgradeStrip();
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [recentsOpen, setRecentsOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { isPromoRibbonActive } = usePromotions();

  const { isWhiteLabel } = config;
  const splashPath = config.splashPageType === 'none' ? '/members' : '/';
  const isSplash = !config.headerBar?.splashMenuLinks ? location.pathname === splashPath : false;

  const { mobileMenuOpened, setMobileMenuOpened, setActiveNestedMobileMenu } = useContext(HeaderContext);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpened((opened) => {
      if (opened) {
        setActiveNestedMobileMenu(null);
      }

      return !opened;
    });
  }, [setMobileMenuOpened, setActiveNestedMobileMenu]);

  const handleSearchClose = useCallback(() => {
    searchButtonDisabledRef.current = true;
    setSearchBarOpen(false);
    setTimeout(() => {
      searchButtonDisabledRef.current = false;
    }, 100);
  }, []);

  const handleSearchSubmit = useCallback(
    (values) => {
      if (!values.searchTerm || values.searchTerm.trim() === '') {
        throw new SubmissionError({ searchTerm: 'empty value' });
      }
      history.push(`/search/${values.searchTerm || '-'}`);
      setSearchBarOpen(false);
    },
    [history],
  );

  return (
    <>
      <HeaderBarWrapper>
        <MainHeader className="navbar navbar-default navbar-fixed-top" ref={mainHeader}>
          {isPromoRibbonActive && <PromoHeaderRibbon />}

          <PartnerHeaderBanner />

          <UpgradeStrip render={upgradeStripOpen} close={closeUpgradeStrip} />

          {config.headerBar?.showHeaderStrip && (
            <HeaderStrip path={`${cdnUrl}/headerStrips/${site.abbreviation}.png`} />
          )}

          <Container className="container no-pad">
            <PopoverSitePicker />

            <MobileMenuButton mobileMenuOpened={mobileMenuOpened} onClick={toggleMobileMenu}>
              <FontAwesomeIcon icon={mobileMenuOpened ? faArrowLeft : faBars} />
            </MobileMenuButton>

            <MobileMenuDrawer isSplash={isSplash} />

            <NavLink to="/" className="logo">
              <img src={logoUrl(cdnUrl, site)} alt={site.name} />
            </NavLink>

            <div className="inline-block va-m pull-right">
              <ProfileIcons>
                {config.featureFlags?.enableSeoCategoriesMenu && !user && <SeoCategoriesMenu />}
                <LiveGirlsMenu shouldRender={!user && !isSplash && !isWhiteLabel} />
                {!user &&
                  isSplash && [
                    <a
                      href={`/login?r=${location.pathname.replace(/^\//, '')}`}
                      className="hidden-xs hidden-sm login-btn"
                      key="login"
                    >
                      MEMBERS LOGIN
                    </a>,
                    ...(!isWhiteLabel
                      ? [
                          <JoinButton
                            campaign="header-bar"
                            className="btn hidden-xs hidden-sm join-btn"
                            data-value="splash"
                            key="join"
                          >
                            JOIN NOW
                          </JoinButton>,
                        ]
                      : []),
                  ]}

                {!user && isWhiteLabel && (
                  <a
                    href={`/login?r=${location.pathname.replace(/^\//, '')}`}
                    className="btn hidden-xs hidden-sm join-btn wl-join-btn"
                    key="login"
                  >
                    LOGIN NOW
                  </a>
                )}

                {!user &&
                  !isSplash &&
                  !isWhiteLabel && [
                    <div key="join" className="hidden-xs hidden-sm inline-block">
                      <JoinButton
                        campaign="header-bar"
                        className="btn btn-primary join-btn"
                        data-value="regular"
                        onClick={sendGetStartedEventOnClick}
                      >
                        <FormattedMessage id="header.links.getStarted" defaultMessage="get started" />
                      </JoinButton>
                    </div>,
                    <JoinButton
                      campaign="header-bar"
                      className="hidden-md hidden-lg hidden-xl join-btn guest-btns"
                      data-value="regular-mobile"
                      key="login-mobile"
                      onClick={sendGetStartedEventOnClick}
                    >
                      <FormattedMessage id="header.links.getStarted" defaultMessage="get started" />
                    </JoinButton>,
                    <a
                      href={`/login?r=${location.pathname.replace(/^\//, '')}`}
                      className="sign-in guest-btns"
                      key="login"
                    >
                      <FormattedMessage id="header.links.signIn" defaultMessage="sign in" />
                    </a>,
                  ]}

                <RightButtons>
                  {config.featureFlags?.enableSeoCategoriesMenu && user && <SeoCategoriesMenu />}
                  <LiveGirlsMenu shouldRender={!isWhiteLabel && !!user} />
                  <MoreGirls />
                  {!isWhiteLabel && user && !user.validSubscription && (
                    <div className="hidden-xs hidden-sm inline-block">
                      <JoinButton
                        className="btn btn-primary join-btn join-btn-header"
                        data-value="regular"
                        campaign="header-bar"
                      >
                        JOIN NOW
                      </JoinButton>
                    </div>
                  )}

                  {!isWhiteLabel && !isSplash && (
                    <Tooltip
                      value={<FormattedMessage id="header.tooltip.search" defaultMessage="Search" />}
                      placement="bottom"
                    >
                      <ImageButton
                        className={cx('hidden-xs', 'hidden-sm', 'clickable', { active: searchBarOpen })}
                        onClick={() => {
                          if (!searchButtonDisabledRef.current) {
                            setSearchBarOpen((open) => !open);
                          }
                        }}
                      >
                        <NavbarIcon icon={faSearch} />
                      </ImageButton>
                    </Tooltip>
                  )}

                  <UserMenuItems
                    key="user-header-menu"
                    mainHeader={mainHeader}
                    toggleRecentsBox={() => setRecentsOpen((open) => !open)}
                    closeNotificationPanel={() => setNotificationsOpen((open) => !open)}
                  />
                </RightButtons>
              </ProfileIcons>
            </div>
            <InternalLinks isSplash={isSplash} />
          </Container>
        </MainHeader>
      </HeaderBarWrapper>

      <SearchBoxContainer isOpened={searchBarOpen} onClose={handleSearchClose} onSubmit={handleSearchSubmit} />

      <RecentsBox isOpened={recentsOpen} onClose={() => setRecentsOpen(false)} />

      {user && config.hasSiteNotifications && (
        <SiteNotificationsPanel active={notificationsOpen} onClose={() => setNotificationsOpen(false)} />
      )}

      <MobileMenuScrim show={mobileMenuOpened} onClick={() => setMobileMenuOpened(false)} />
    </>
  );
};

const ProfileIcons = styled.div`
  display: inline-flex;
  align-items: center;

  > a {
    display: inline-block;
    line-height: 60px;
    margin: 0;
    padding: 0 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    vertical-align: middle;

    @media (max-width: 479px) {
      padding: 0 7px;
      font-size: 0.9rem;
    }

    .sign-in {
      text-transform: capitalize;
    }
  }

  > a.popover-btn {
    padding-right: 0;

    > * {
      margin-right: 6px;
      vertical-align: middle;
    }
  }

  > a.i18n-select {
    span {
      font-size: 1.15rem;
    }
  }

  a.join-btn {
    background: ${(props) => props.theme.headerBarPrimary3};
    color: ${(props) => props.theme.primaryBtn1Text} !important;
    opacity: 1;
    text-transform: uppercase;

    &.join-btn-header {
      position: relative;
      top: -2px;
    }

    &.wl-join-btn {
      position: relative;
      top: 10px;
    }

    :hover {
      opacity: 0.9;
    }

    @media (max-width: 1200px) {
      padding: 8px 11px;
    }
  }
`;

const MainHeader = styled.div`
  border-style: none;
  min-height: 64px; /* to keep guest and user headerbar same height */
  background: ${(props) => props.theme.headerBarPrimary1};
  top: 0;
  z-index: 1030;

  @media (max-width: 991px) {
    background: ${(props) => props.theme.headerBarPrimary5};
  }

  &.navbar {
    margin-bottom: 0;
  }
`;

const MobileMenuScrim = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.6;
  z-index: 1000;
  display: ${(props) => (props.show ? 'block' : 'none')};

  @media ${TABLET_LANDSCAPE} {
    display: none;
  }
`;

const RightButtons = styled.div`
  display: flex;
  align-items: center;
`;
