import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch, Route, useParams, Redirect } from 'react-router';
import { ContentHeader, Tab, Tabs } from '@tovia/man-ui';
import { Link } from 'react-router-dom';
import LoaderWrapper from 'src/client/components/Loader/LoaderWrapper';
import GalleriesContent from '../Galleries/GalleriesContent/GalleriesContent';
import { load as loadModels, reset } from 'src/client/redux/modules/model';
import { parseSlug } from 'src/client/utils/converters';
import { ModelPanels } from '../Panels/ModelPanels';
import NetworkGalleriesSlider from '../NetworkGalleriesSlider/NetworkGalleriesSlider';
import RecentCookiesItemInjector from '../RecentCookies/RecentCookiesItemInjector';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import Helmet from '../Helmet/Helmet';

import { isPrerender } from 'src/client/utils/prerenderDetect';
import { Tab as TabType, useTabs } from 'src/client/helpers';
import { getGallerySortTypes, getMovieSortTypes, modelsAndArtistsRoutes, routes } from 'src/client/utils/router';
import { ModelLatest } from './ModelLatest';
import { use404Redirect } from 'src/client/helpers';
import { MessageModal } from './MessageModal';
import { isSeoModifiedModel } from 'src/client/containers/Model/seoModifiedModels';
import { CommentSection } from '../CommentSection/CommentSection';

const {
  modelsAndArtists: {
    routes: { modelMovies: modelMoviesRoute, modelLatest: modelLatestRoute, modelPhotos: modelPhotosRoute },
  },
} = routes;

const makeGold = (ref: HTMLAnchorElement | null) => ref?.style?.setProperty('color', '#CEAB58', 'important');

export default function Model() {
  const { cdnUrl, pixelApiUrl, config } = useSelector((state) => state.app);
  const { instagram, modelPageTabs, isWhiteLabel } = config;

  const site = useSelector((state) => state.site);
  const { error, item, loading } = useSelector((state) => state.model);
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  const sortByGalleries = useSettingsSelector('galleriesSorting');
  const sortByFilms = useSettingsSelector('filmsSorting');

  const [open, setOpen] = useState(false);
  const onHide = useCallback(() => setOpen(false), []);
  const onShow = useCallback(() => {
    setOpen(true);

    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'model-video-message',
      eventLabel: 'interest-dialog',
      eventAction: 'show',
    });
  }, []);

  const dispatch = useDispatch();
  const params = useParams<{ modelName: string; page?: string; tab: string; sortBy?: string }>();
  const { modelName, page = 1 } = params;

  useEffect(() => {
    if (!isPrerender && item) {
      window.dataLayer.push({
        event: 'select_content',
        content_type: 'model',
        item_id: item.name,
      });
    }
  }, [item]);

  use404Redirect(error);

  const after = (Number(page) - 1) * 20;

  const loadData = useCallback(() => {
    dispatch(loadModels({ name: parseSlug(modelName), sortBy: 'latest', after }));
  }, [dispatch, modelName, after]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add('show-jsd'); // show jsd plugin

    return () => document.body.classList.remove('show-jsd');
  }, []);

  const itemName = item?.name || '';
  const modelTabs: TabType[] = [
    {
      value: 'latest',
      to: `/model/${modelName}/latest`,
      name: intl.formatMessage({ id: 'contentHeader.model.latest' }),
      // @SEO_CLEANUP
      headerTitle: isSeoModifiedModel(item?.UUID, site.abbreviation)
        ? `${itemName} Nude Photos and Videos`
        : `Latest Photos and Films with ${itemName}`,
    },
  ];

  const sortBy = params.sortBy || 'top';
  if (Number(item?.moviesCount) > 0) {
    const sort = getMovieSortTypes(config).find((cSort) => cSort.id === sortBy)?.title || '';

    modelTabs.push({
      value: 'movies',
      to: `/model/${modelName}/movies/${sortByFilms || 'top'}`,
      name: intl.formatMessage({ id: 'contentHeader.model.movies' }),
      headerTitle: intl.formatMessage({ id: 'model.filmsWith' }, { itemName, sort }),
    });
  }

  if (Number(item?.galleriesCount) > 0) {
    const sort = getGallerySortTypes(config).find((cSort) => cSort.id === sortBy)?.title || '';

    modelTabs.push({
      value: 'photos',
      to: `/model/${modelName}/photos/${sortByGalleries || 'top'}`,
      name: intl.formatMessage({ id: 'contentHeader.model.photos' }),
      headerTitle: intl.formatMessage({ id: 'model.photosWith' }, { itemName, sort }),
    });
  }

  const [tabs, activeTab] = useTabs(modelTabs, modelsAndArtistsRoutes);

  const renderContentHeader = useCallback(() => {
    if (!activeTab) {
      return null;
    }

    const tabComponents = tabs.map((tab) => (
      <Tab key={tab.value} as={Link} active={activeTab.value === tab.value} {...tab} />
    ));

    if (modelPageTabs.includes('model-message') && user) {
      tabComponents.push(
        <a style={{ color: '#CEAB58' }} className="tab" onClick={onShow} ref={makeGold}>
          <FormattedMessage
            id="model.personalVideo"
            values={{ name: item?.name || '' }}
            defaultMessage={`Get a personal video message from {name}`}
          />
        </a>,
      );
    }

    const tabsNode = <Tabs>{tabComponents}</Tabs>;

    return <ContentHeader tabs={tabsNode} title={activeTab.headerTitle} />;
  }, [activeTab, item, modelPageTabs, tabs, user, onShow]);

  const metaData = useMemo(() => {
    if (!item) {
      return {};
    }

    const { name, moviesCount, galleriesCount, publishAge, eyes, breasts, hair, debutMonth, debutYear } = item;

    return {
      debutMonth,
      debutYear,
      modelName: name,
      hairColor: hair,
      setsCount: moviesCount + galleriesCount,
      debutAge: publishAge,
      eyeColor: eyes,
      boobSize: breasts,
      siteName: site.name,
      domainName: site.domain,
    };
  }, [site, item]);

  if (!item) {
    return (
      <div className="content container">
        <LoaderWrapper
          defaultHeight
          reloadFunction={loadData}
          loadingStatus={loading}
          reduxErrorStatus={error}
          contentType="model"
        />
      </div>
    );
  }

  const { UUID, globalContent, name } = item;

  return (
    <>
      <Helmet id="model" defaultTitle="{{modelName}} - {{siteName}}" variables={metaData} />
      <div className="content container">
        <img src={`${pixelApiUrl}/${item.siteUUID}/MODEL/${item.UUID}`} style={{ display: 'none' }} alt="pixel" />
        <StyledModelPanels model={item} isModelPage />
        {renderContentHeader()}

        <Switch>
          <Route
            path={modelMoviesRoute.path}
            exact
            render={() => (
              <GalleriesContent sortSettingsKey="filmsSorting" UUID={UUID} galleryType="MOVIE" pageType="model" />
            )}
          />
          <Route
            path={modelPhotosRoute.path}
            exact
            render={() => <GalleriesContent UUID={UUID} galleryType="GALLERY" pageType="model" />}
          />
          <Route path={modelLatestRoute.path} exact render={() => <ModelLatest item={item} loadData={loadData} />} />
          <Redirect to={modelLatestRoute.path as string} />
        </Switch>

        {!isWhiteLabel && (
          <CommentSection objectUUID={UUID} parentItem={item} parentItemType="model" campaign="model-comment" />
        )}

        {globalContent.length ? (
          <NetworkGalleriesSlider
            galleries={globalContent}
            title={intl.formatMessage(
              { id: 'networkGallerySlider.title', defaultMessage: `More of ${name} in MetArt Network` },
              { modelName: name },
            )}
          />
        ) : null}

        <RecentCookiesItemInjector
          type="model"
          path={item.path}
          name={item.name}
          siteUUID={item.siteUUID}
          headshotImagePath={item.headshotImagePath}
        />
        <MessageModal cdnUrl={cdnUrl} instagram={instagram} onHide={onHide} show={open} siteName={site.name} />
      </div>
    </>
  );
}

const StyledModelPanels = styled(ModelPanels)`
  margin-top: 20px;
`;
