import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { useDebounce } from 'react-use';

export const DISPLAY_NAME_MIN_LENGTH = 4;
export const DISPLAY_NAME_CHARS_REGEX = /[^a-z0-9-_\s.]/gi;
export const DISPLAY_NAME_MAX_LENGTH = 30;

export const useDisplayName = () => {
  const intl = useIntl();
  const [newDisplayName, setFormattedDisplayName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [displayNameResponse, setDisplayNameResponse] = useState<any>(null);
  const [displayNameError, setDisplayNameError] = useState<null | string>(null);
  const [displayNameStatus, setDisplayNameStatus] = useState<null | boolean | 'loading'>(null);

  const setNewDisplayName = (name: string) => {
    if (name) {
      return setFormattedDisplayName(name.replace(DISPLAY_NAME_CHARS_REGEX, '').substr(0, DISPLAY_NAME_MAX_LENGTH));
    }
    return setFormattedDisplayName('');
  };

  useEffect(() => {
    let message = '';
    switch (displayNameResponse?.reason) {
      case 'already taken':
        message = intl.formatMessage({
          id: 'comments.form.error.displayName_taken',
          defaultMessage: 'Display name is already in use.',
        });
        break;
      case 'already set':
        message = intl.formatMessage({
          id: 'comments.form.error.displayName_set',
          defaultMessage: 'Display name has already been set, please refresh.',
        });
        break;
      case 'invalid':
      default:
        message = intl.formatMessage({
          id: 'comments.form.error.displayName_invalid',
          defaultMessage:
            'Must be 4-30 characters long and contain letters, numbers, dashes, underscores, periods or spaces.',
        });
        break;
    }

    if (displayNameResponse?.success === false) {
      return setDisplayNameError(message);
    }
    setDisplayNameError(null);
  }, [displayNameResponse, intl]);

  useDebounce(
    async () => {
      if (newDisplayName && newDisplayName.length >= DISPLAY_NAME_MIN_LENGTH) {
        const api = new ApiClient();
        try {
          setDisplayNameStatus('loading');
          const response = await api.get(
            constructUrl(urls.get.validateDisplayName.replace(':name', newDisplayName.trim())),
            {},
          );
          setDisplayNameStatus(true);
          setDisplayNameResponse(response?.body);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          setDisplayNameStatus(false);
          setDisplayNameResponse(error?.response?.body);
        }
      }
    },
    500,
    [newDisplayName],
  );

  return { newDisplayName, setNewDisplayName, displayNameError, displayNameStatus, setDisplayNameError };
};
