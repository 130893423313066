import { take, call, fork, select } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/siteNotifiations/LOAD';
const LOAD_SAGA = 'man-site/siteNotifiations/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/siteNotifiations/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/siteNotifiations/LOAD_FAIL';
const VIEW_NOTIFICATIONS_SAGA = 'man-site/siteNotifiations/VIEW_NOTIFICATIONS_SAGA';
const VIEW_NOTIFICATIONS = 'man-site/siteNotifiations/VIEW_NOTIFICATIONS';
const VIEW_NOTIFICATIONS_SUCCESS = 'man-site/siteNotifiations/VIEW_NOTIFICATIONS_SUCCESS';
const VIEW_NOTIFICATIONS_FAIL = 'man-site/siteNotifiations/VIEW_NOTIFICATIONS_FAIL';

const limit = 10;

export const SITE_NOTIFICATION_TYPES = {
  1: {
    id: 'newFeature',
    title: 'new feature',
    intlID: 'siteNotifications.type.newFeature',
  },
  2: {
    id: 'bugFix',
    title: 'bug fix',
    intlID: 'siteNotifications.type.bugFix',
  },
  3: {
    id: 'announcement',
    title: 'announcement',
    intlID: 'siteNotifications.type.announcement',
  },
  4: {
    id: 'featureUpgrade',
    title: 'feature upgrade',
    intlID: 'siteNotifications.type.featureUpgrade',
  },
};

const endpoint = constructUrl(urls.get.siteNotifications);
const setEndpoint = constructUrl(urls.post.lastViewedSiteNotification);

const initialItems = {};

const initialState = {
  loading: false,
  loaded: false,
  items: initialItems,
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case VIEW_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          newNotificationsCount: 0,
        },
      };
    case VIEW_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function setLastViewedNotification(params) {
  return {
    type: VIEW_NOTIFICATIONS_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.siteNotifications;
  const currentState = yield select(getState);
  if (!currentState.loaded && !currentState.loading) {
    const getNotificationsParams = {
      ...params,
      limit,
    };
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) =>
      client.get(endpoint, { params: getNotificationsParams })
    );
    yield call(loadFunc);
  }
}

function* viewGenerator(params) {
  const viewNotificationFunc = getRequestFunc(
    [VIEW_NOTIFICATIONS, VIEW_NOTIFICATIONS_SUCCESS, VIEW_NOTIFICATIONS_FAIL],
    (client) =>
      client.post(setEndpoint, {
        data: {
          ...params,
        },
      })
  );
  yield call(viewNotificationFunc);
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

function* watchViewNotifications() {
  while (true) {
    const { params } = yield take(VIEW_NOTIFICATIONS_SAGA);
    yield call(viewGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchViewNotifications)];
/* EOF SAGAS */
