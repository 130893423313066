import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/userComments/LOAD';
const LOAD_SAGA = 'man-site/userComments/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/userComments/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/userComments/LOAD_FAIL';

const endpoint = constructUrl(urls.get.comments);

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  lastCallParams: {},
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        loading: true,
        lastCallParams: action.lastCallParams,
      };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        items: action.result.comments,
        loading: false,
        loaded: true,
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.userComments;
  const currentState = yield select(getState);
  const { lastCallParams } = currentState;
  if ((!isLoaded(lastCallParams, params) && !currentState.loading) || (!currentState.loading && !currentState.loaded)) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      lastCallParams: { ...params },
    });
    yield call(loadFunc);
  }

  if (currentState.items.length > 0) {
    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);
    const newState = yield select();
    if (newState.auth.user && newState.userComments.items.length > 0) {
      yield put(
        loadRatingInfo({
          objectUUIDs: [...newState.userComments.items.map((item) => item.UUID)],
        })
      );
    }
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
