import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components/macro';
import { useSelector } from '../../redux/modules/helpers/useSelector';

const PopupIconButton = styled.div`
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.72rem;
  align-self: center;
  margin: 5px;
  transition: transform 0.3s;
  cursor: pointer;

  &.favorited {
    color: #c10000;
  }

  &:hover:not(:active) {
    transform: scale(1.1);
  }
`;

const Div = styled.div`
  &.opened {
    .options-menu {
      left: 0;
    }
  }

  .options-menu {
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    z-index: 1;
    background: rgb(0, 0, 0, 0.5);
    text-align: center;
    overflow: hidden;
    position: absolute;
    transition: left 0.8s;
    display: flex;
  }

  .menu-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-self: center;
    width: 100%;
  }

  .close-button {
    position: absolute;
    right: 0;
    padding: 10px;
    color: white;
    cursor: pointer;
  }

  .plus {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    color: #fff;

    &:hover .border {
      opacity: 0.8;
      border-top-color: rgba(0, 0, 0, 0.87);
      border-right-color: rgba(0, 0, 0, 0.87);
    }

    .border {
      border-top: 30px solid rgba(0, 0, 0, 0.5);
      border-bottom: 30px solid transparent;
      border-left: 30px solid transparent;
      border-right: 30px solid rgba(0, 0, 0, 0.5);
    }

    svg {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 10px;
      width: 1em;
      height: 1.25em;
    }
  }
`;

export default function GirdCardMenuOverlay(props) {
  const { onFavorite, onShare, isOpen, setOpen, isFavorited } = props;
  const { config } = useSelector((state) => state.app);
  if (config.isWhiteLabel) {
    return null;
  }
  return (
    <Div className={`${isOpen ? 'opened' : ''}`}>
      <div className="options-menu">
        <div className="close-button" onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="menu-buttons">
          <PopupIconButton
            className={`button ${isFavorited ? 'favorited' : ''}`}
            onClick={() => {
              onFavorite();
            }}
          >
            <FontAwesomeIcon icon={isFavorited ? faHeartSolid : faHeart} />
          </PopupIconButton>
          <PopupIconButton
            className="button"
            onClick={() => {
              onShare();
            }}
          >
            <FontAwesomeIcon icon={faLink} />
          </PopupIconButton>
        </div>
      </div>
      {!isOpen && (
        <div
          className="plus"
          onClick={(e) => {
            setOpen(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="border" />
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
      {/* <CardMenuOverlay */}
      {/*  icon={faChevronDown} */}
      {/*  onClick={(e) => { */}
      {/*    setOpen(true); */}
      {/*    e.preventDefault(); */}
      {/*    e.stopPropagation(); */}
      {/*  }} */}
      {/* /> */}
    </Div>
  );
}

GirdCardMenuOverlay.propTypes = {
  onShare: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  isFavorited: PropTypes.bool,
  isOpen: PropTypes.bool,
};

GirdCardMenuOverlay.defaultProps = {
  isFavorited: false,
  isOpen: false,
};
