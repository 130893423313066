import { useEffect } from 'react';
import { useCookies } from 'react-cookie-latest';
import { useSelector } from 'src/client/redux/';
import { PREVIEW_TRACKER_COOKIE } from 'src/shared/constants/cookies';
import { MetArt } from 'src/client/constants/sites';
import { googleAnalyticsEvents } from 'src/shared/constants/googleEvents';

type Args = {
  // The counter that lets us know how long the user hovered a preview card
  hoverDuration: number | null;
};

// The preview animation is set to 12 seconds, so if the user passes quarter of it,
// we can kinda make sure they are actually using the feature instead of randomly hovering.
const PREVIEW_FEATURE_WATCH_MINIMUM = 3000;

export const usePushGooglePreviewEvent = ({ hoverDuration }: Args) => {
  const [cookies, setCookies] = useCookies([PREVIEW_TRACKER_COOKIE]);
  const previewTrackerCookie = cookies[PREVIEW_TRACKER_COOKIE];
  const site = useSelector((state) => state.site);
  const isMetart = site?.abbreviation === MetArt.abbreviation;
  const isAppropriateDuration = hoverDuration && hoverDuration >= PREVIEW_FEATURE_WATCH_MINIMUM;
  const shouldPushGooglePreviewEvent = !previewTrackerCookie && isAppropriateDuration && isMetart;

  useEffect(() => {
    if (!shouldPushGooglePreviewEvent) {
      return;
    }

    window.dataLayer.push({
      event: googleAnalyticsEvents.standardEvent.event,
      eventCategory: googleAnalyticsEvents.movieSlideShowEvent.eventCategory,
    });

    setCookies(PREVIEW_TRACKER_COOKIE, true, { path: '/' });
  }, [setCookies, shouldPushGooglePreviewEvent]);
};
