import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import styled, { keyframes } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { reset } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { Comment, SortDropdown, Panel, Panels } from '@tovia/man-ui';
import { CommentForm } from '../../components/forms/CommentForm';
import { badgeMap } from 'src/client/helpers';
import { setValue } from '../../redux/modules/settings';
import {
  post,
  load as loadComments,
  setDisplayName as setDisplayNameFn,
  validateDisplayName,
  SORT_TYPES,
} from '../../redux/modules/comments';
import { setDisplayName as setAppDisplayNameFn } from '../../redux/modules/auth';
import { rateItem } from '../../redux/modules/ratingInfo';
import { show as showShareDialog } from '../../redux/modules/shareDialog';
import moment from 'moment';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';
import { useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';

const getTimeAgo = ({ date }) => moment(new Date(date)).fromNow();

const getFormName = (parentUUID) => `parent-${parentUUID}`;

const textBoxFieldName = 'new-comment-message';

const sanitizeType = (str) => {
  const types = {
    blogPost: 'blog',
    movie: 'gallery',
  };
  return types[str] || str;
};

const pulseAnimation = (props) => keyframes`
  from {
    box-shadow: 0 0 0 0 ${props.themeColor === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
  }
  to {
    box-shadow: 0 0 0 35px ${props.themeColor === 'light' ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)'};
  }
`;

type PropTypes = {
  campaign?: string;
  generateTitle?: (params) => void;
  id?: string;
  objectUUID: string;
  onPostSuccess?: () => void;
  parentItem: { path: string; UUID: string };
  parentItemType: string;
};

type CommentParam = {
  objectType: string;
  objectUUID: string;
  parentUUID?: string;
  text: string;
  UUID?: string;
};

export const CommentSection = (props: PropTypes) => {
  const intl = useIntl();
  const generateJoinUrl = useJoinUrlGenerator();
  const {
    objectUUID,
    parentItem,
    parentItemType,
    // eslint-disable-next-line no-console
    onPostSuccess = () => console.log('submit succeeded'),
    generateTitle,
  } = props;
  const [isFormTriggered, setIsFormTriggered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [sortBy, setSortBy] = useState(useSettingsSelector<string>('commentSorting'));
  const comments = useSelector((state) => state.comments?.comments[objectUUID]?.comments || []);
  const commentsCount = useSelector((state) => state.comments?.comments[objectUUID]?.commentsCount || 0);
  const { postStatus, loadingObjectUUIDs } = useSelector((state) => state.comments);
  const prevPostStatus = useRef(postStatus);
  const ratings = useSelector((state) => state.ratingInfo.ratings);
  const user = useSelector((state) => state.auth.user);
  const showComments = Boolean(useSettingsSelector<number>('comments'));
  const commentSection = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    const { order, direction } = SORT_TYPES.find((type) => type.id === sortBy) || {};
    const params = {
      objectUUID,
      order: order || 'TIMESTAMP',
      direction: direction || 'DESC',
    };

    dispatch(loadComments(params));
  }, [dispatch, objectUUID, sortBy]);

  useEffect(() => {
    if (postStatus === 'success' && postStatus !== prevPostStatus.current) {
      if (onPostSuccess) {
        onPostSuccess(); // run the callback
      }

      if (isFormTriggered) {
        dispatch(reset(getFormName(parentItem.UUID)));
        setIsFormTriggered(false);
      }
    }
    prevPostStatus.current = postStatus;
  }, [dispatch, postStatus, onPostSuccess, isFormTriggered, prevPostStatus, parentItem.UUID]);

  useEffect(() => {
    const { hash } = location;
    if (!scrolled && comments.length && commentSection.current && hash) {
      const commentId = hash.replace('#', '');
      const element = document.getElementById(commentId);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100;
        window.scrollTo({
          top: y + yOffset,
          behavior: 'smooth',
        });
        setScrolled(true);
        setTimeout(() => element.classList.add('pulse-animation'), 1250);
      }
    }
  }, [comments, scrolled]);

  const changeSort = (value) => {
    if (value === sortBy) {
      return;
    }
    const [{ order, direction, id }] = SORT_TYPES.filter((sortType) => sortType.id === value);
    const params = {
      objectUUID,
      order,
      direction,
      id,
    };
    dispatch(loadComments(params));
    setSortBy(value);
    dispatch(setValue('commentSorting', value));
  };

  const findParent = (array, comment) => {
    if (!comment) {
      return {
        UUID: 'deleted',
      };
    }

    if (comment.parentUUID) {
      const parentComment = array.find((c) => c.UUID === comment.parentUUID);
      return findParent(array, parentComment);
    }

    return comment;
  };

  /*
   DON'T CHANGE THIS FUNCTION, it needs to modify the param and
   violate eslint to enable nesting all comments in linear rather than quadratic time
   */
  const nestComments = (array) => {
    /* eslint-disable no-param-reassign */
    const arrayToNestedObj = (UIDField, parentIDField, childField) => {
      const groupByKey = (keyField) =>
        array.reduce((r, ele) => {
          if (ele[keyField]) {
            const topParent = findParent(array, ele);
            r[topParent.UUID] = [ele, ...(r[topParent.UUID] || [])];
          }
          return r;
        }, {});

      const children = groupByKey(parentIDField);
      return array
        .reduce((r, ele) => {
          if (children[ele[UIDField]]) {
            ele[childField] = children[ele[UIDField]];
          }
          r.push(ele);
          return r;
        }, [])
        .filter((ele) => !ele[parentIDField]);
    };
    return arrayToNestedObj('UUID', 'parentUUID', 'childComments');
  };

  const postComment = (values, parentCommentUUID) => {
    const params: CommentParam = {
      objectUUID: parentItem.UUID,
      objectType: sanitizeType(parentItemType),
      text: values[textBoxFieldName],
      UUID: user?.UUID,
    };
    if (parentCommentUUID) {
      params.parentUUID = parentCommentUUID;
    }
    dispatch(post(params));
  };

  const comment2Props = (comment) => {
    const parentUrl = parentItem.path;
    const isRated = ratings[comment.UUID] === 1;
    const badgeId = comment.badges;

    return {
      badge: badgeMap[badgeId],
      displayName: comment.userDisplayName,
      isRated,
      loggedInUserName: user && user?.displayname,
      onCommentSubmit: (values) => {
        postComment(values, comment.UUID);
      },
      onRate: () =>
        dispatch(
          rateItem({
            objectUUID: comment.UUID,
            objectType: 'COMMENT',
            rating: isRated ? 0 : 1, // toggle rating between 0 and 1
          }),
        ),
      parentUrl,
      parentUUID: comment.parentUUID, // needed for nextComments
      postStatus,
      ratesCount: comment.rating || 0,
      text: comment.text,
      timestamp: comment.timestamp,
      UUID: comment.UUID,
    };
  };

  const submitOwnForm = (values) => {
    setIsFormTriggered(true);
    postComment(values, '');
  };

  const setDisplayName = async (name) => {
    const result = await setDisplayNameFn(name);
    if (result.success) {
      dispatch(setAppDisplayNameFn(name));
    }

    return result;
  };

  const displayName = user ? user.displayname : intl.formatMessage({ id: 'comments.guest', defaultMessage: 'Guest' });

  const parentUrl = parentItem.path;

  const renderForm = () => (
    <CommentForm
      link={parentUrl}
      user={user}
      form={getFormName(parentItem.UUID)}
      displayName={displayName}
      showUpgradeDialog={() => redirectToJoin(generateJoinUrl, 'comment-form')}
      postStatus={postStatus}
      setDisplayName={setDisplayName}
      validateDisplayName={validateDisplayName}
      onSubmit={submitOwnForm}
    />
  );

  const commentsProps = comments.map(comment2Props);
  const member = user && user.validSubscription;
  const loading = loadingObjectUUIDs.includes(objectUUID);

  if (loading) {
    return (
      <Panels>
        <Panel>
          <h3 className="headline">
            {`${intl.formatMessage({ id: 'comments.loadingComments', defaultMessage: 'Loading Comments' })}...`}
          </h3>
        </Panel>
      </Panels>
    );
  }

  if (commentsCount === 0 && !user) {
    return <span />;
  }

  return (
    <Panels>
      <StyledPanel className="comment-section" id="comment-section">
        <div className="panel-body">
          <h3 className="pull-left headline">
            {(generateTitle &&
              generateTitle({
                user,
                commentCount: commentsCount,
              })) ||
              (user &&
                `${commentsCount} ${intl.formatMessage({ id: 'comments.comments', defaultMessage: 'Comments' })}`) ||
              `${intl.formatMessage({
                id: 'comments.memberComments',
                defaultMessage: 'Member Comments',
              })} (${commentsCount})`}
          </h3>
          {member && (
            <a
              id="toggle-btn"
              className="pull-left btn text-uppercase btn-toggle"
              onClick={() => {
                dispatch(setValue('comments', showComments ? 0 : 1));
              }}
            >
              <FontAwesomeIcon icon={showComments ? faEyeSlash : faEye} />{' '}
              {showComments
                ? intl.formatMessage({ id: 'comments.buttons.hide', defaultMessage: 'Hide Comments' })
                : intl.formatMessage({ id: 'comments.buttons.show', defaultMessage: 'Show Comments' })}
            </a>
          )}
          <div className="sort-wrap">
            <SortDropdown menuOptions={SORT_TYPES} value={sortBy} onChange={changeSort} />
          </div>
          <div className="clear" />
          {member && renderForm()}
          <CommentPanel>
            {(!member || showComments) &&
              nestComments(commentsProps).map((commentProps) => (
                <Comment
                  ref={(input) => {
                    commentSection.current = input?.value || '';
                  }}
                  user={{ displayname: user?.displayname || '' }}
                  isUser={member || false}
                  showUpgradeDialog={() => redirectToJoin(generateJoinUrl, 'comment')}
                  key={commentProps.UUID}
                  UUID={commentProps.UUID}
                  parentUUID={commentProps.parentUUID}
                  parentUrl={commentProps.parentUrl}
                  onRate={commentProps.onRate}
                  isRated={commentProps.isRated}
                  ratesCount={commentProps.ratesCount}
                  badge={commentProps.badge}
                  displayName={commentProps.displayName}
                  text={commentProps.text}
                  timestamp={commentProps.timestamp}
                  postStatus={commentProps.postStatus}
                  onCommentSubmit={commentProps.onCommentSubmit}
                  childComments={commentProps.childComments}
                  showShareDialog={(link) => dispatch(showShareDialog(link, 'comment', commentProps.UUID))}
                  commentForm={renderForm()}
                  renderRelativeDate={(date) => getTimeAgo({ date })}
                  replyText={intl.formatMessage({ id: 'comments.actions.reply', defaultMessage: 'Reply' })}
                  shareText={intl.formatMessage({ id: 'comments.actions.share', defaultMessage: 'Share' })}
                />
              ))}
          </CommentPanel>
        </div>
      </StyledPanel>
    </Panels>
  );
};

const CommentPanel = styled.div`
  word-break: break-word;
`;

const StyledPanel = styled(Panel)`
  & .sort-wrap {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 797px) {
      display: flex;
      width: 100%;
      position: relative;
      justify-content: flex-start;

      .dropdown-toggle {
        padding: 0;
        margin: 8px 0;
      }
    }
  }

  & .btn-toggle {
    margin-left: 20px;
    margin-top: -5px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.primary7};
    color: ${(props) => props.theme.primary7};

    @media (max-width: 767px) {
      font-size: 0.75rem;
      padding: 3px 6px;
      margin: 0;
    }
  }

  &.panel-default {
    border-color: ${(props) => props.theme.primary3};
  }

  &.panel {
    border-radius: 0;
  }

  & .headline {
    font-weight: 300;
    margin-top: 0;

    @media (max-width: 767px) {
      font-size: 1.28rem;
      padding-right: 10px;
      line-height: 25px;
    }
  }

  & .pulse-animation {
    animation: ${(props) => pulseAnimation(props)} 1s;
  }

  & .comment-actions {
    color: ${(props) => props.theme.primary7};
    :hover {
      color: unset;
    }

    &.active {
      font-weight: bold;
      color: ${(props) => props.theme.primaryBtn1Bg};
    }
  }
`;
