import { DESKTOP, MOBILE_LANDSCAPE, TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';

export const StandardGrid = styled.section`
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  width: 100%;
  position: relative;
  grid-auto-rows: 1fr;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media ${MOBILE_LANDSCAPE} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media ${DESKTOP} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;
