import isEqual from 'lodash/isEqual';
import { take, call, fork, select, put } from 'redux-saga/effects';

import { load as loadGalleryPermissions } from './galleryPermissions';
import urls, { constructUrl } from 'src/shared/urls';
import { getRequestFunc } from 'src/client/helpers';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';
import { Movie } from 'src/@types/Movie';
import { RootState } from './reducer';

const RESET = 'man-site/movie/RESET';
const SET_DETAILS_TAB = 'man-site/movie/SET_DETAILS_TAB';
const CHANGE_LIGHTS = 'man-site/movie/CHANGE_LIGHTS';
const LOAD_SAGA = 'man-site/movie/LOAD_SAGA';
const LOAD = 'man-site/movie/LOAD';
const LOAD_SUCCESS = 'man-site/movie/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/movie/LOAD_FAIL';

const endpoint = constructUrl(urls.get.movie);

type CallParams = {
  token?: string;
};

type State = {
  activeDetailsTabIndex: number;
  decoratorAdded: boolean;
  error?: SuperAgentError;
  globalContent?: Movie['globalContent'];
  isLightsOn: boolean;
  item?: Movie;
  lastCallParams: CallParams | {};
  loaded: boolean;
  loading: boolean;
};

type Load = {
  type: typeof LOAD;
  params: CallParams;
  lastCallParams: CallParams;
};

type LoadSuccess = State & {
  type: typeof LOAD_SUCCESS;
  result: Movie;
};

type LoadFail = {
  type: typeof LOAD_FAIL;
  error: SuperAgentError;
};

type Reset = {
  type: typeof RESET;
};

type SetDetailsTab = {
  type: typeof SET_DETAILS_TAB;
  index: number;
};

type ChangeLights = {
  type: typeof CHANGE_LIGHTS;
  isLightsOn: boolean;
};

type Action = Load | LoadSuccess | LoadFail | Reset | SetDetailsTab | ChangeLights;

const initialState = {
  loading: false,
  loaded: false,
  activeDetailsTabIndex: 0,
  item: undefined,
  globalContent: undefined,
  lastCallParams: {},
  isLightsOn: true,
  error: undefined,
  decoratorAdded: false,
} as State;

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LOAD: {
      return {
        ...initialState,
        loading: true,
        lastCallParams: action.lastCallParams,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ...(action.result
          ? {
              item: action.result,
              globalContent: action.result.globalContent,
            }
          : {}),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        item: initialState.item,
      };
    case RESET:
      return {
        ...initialState,
      };
    case SET_DETAILS_TAB:
      return {
        ...state,
        activeDetailsTabIndex: action.index,
      };
    case CHANGE_LIGHTS:
      return {
        ...state,
        isLightsOn: !state.isLightsOn,
      };
    default: {
      return state;
    }
  }
}

export function reset() {
  return {
    type: RESET,
  };
}

export function toggleLights() {
  return {
    type: CHANGE_LIGHTS,
  };
}

export function isLoaded(lastCallParams: CallParams, params: CallParams) {
  return isEqual(lastCallParams, params);
}

export function load(params: CallParams) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

const getState = (state: RootState) => state.movie;

/* SAGAS */
function* dispatchLoadGalleryPermissions(params: CallParams) {
  const newState = yield select(getState);
  const { item } = newState;
  if (item) {
    yield put(
      loadGalleryPermissions({
        gallery: item.UUID,
        token: params.token,
      })
    );
  }
}

function* loadGenerator(params: CallParams) {
  const currentMovieState = yield select(getState);
  const { lastCallParams } = currentMovieState;

  if (
    (!isLoaded(lastCallParams, params) && !currentMovieState.loading) ||
    (!currentMovieState.loading && !currentMovieState.loaded)
  ) {
    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params,
        }),
      {
        lastCallParams: { ...params },
      }
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  yield call(dispatchLoadGalleryPermissions, params);

  const newState: RootState = yield select();
  if (newState.auth.user && newState.movie.item) {
    const objectUUIDs = [
      newState.movie.item.UUID,
      ...newState.movie.item.models.map((model) => model.UUID),
      ...newState.movie.item.photographers.map((photographer) => photographer.UUID),
    ];
    yield put(loadRatingInfo({ objectUUIDs }));
    yield put(loadFavoriteInfo({ objectUUIDs }));
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield call(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
