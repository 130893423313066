import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { useCookies } from 'react-cookie';
import isArray from 'lodash/isArray';

export const cookieName = 'recents';
export const obj2Recent = (item) => {
  const recentItem: {
    url: string;
    text: string;
    type: string;
    siteUUID: string;
    path?: string;
  } = {
    url: item.path,
    text: item.name,
    type: item.type,
    siteUUID: item.siteUUID,
  };

  if (item.type === 'movie' || item.type === 'gallery') {
    recentItem.path = item.thumbnailCoverPath;
  } else {
    recentItem.path = item.headshotImagePath;
  }

  return recentItem;
};

export const cookieOption = {
  path: '/',
};

export const recentsListSize = 6;

export default function RecentCookiesItemInjector(props) {
  const { headshotImagePath, name, path, siteUUID, thumbnailCoverPath, type } = props;

  const [cookies, setCookie] = useCookies([cookieName]);

  useEffect(() => {
    const recentsList = (isArray(cookies[cookieName]) && cookies[cookieName]) || [];

    const recentItem = obj2Recent({
      path,
      name,
      type,
      siteUUID,
      thumbnailCoverPath,
      headshotImagePath,
    });
    const itemIndex = findIndex(recentsList, (recentObj) => isEqual(recentObj, recentItem));

    if (itemIndex !== -1) {
      setCookie(
        cookieName,
        [recentItem, ...recentsList.slice(0, itemIndex), ...recentsList.slice(itemIndex + 1, recentsListSize)],
        cookieOption
      );
    } else {
      setCookie(cookieName, [recentItem, ...recentsList.slice(0, recentsListSize - 1)], cookieOption);
    }
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps
  // TODO: fix array of dependencies for cookies
  // we have to violate the rules of hooks here or else you will get in an infinite loop. "cookies" and "setCookie"
  // changes each time there is a cookie set or removed, which means this useEffect will fire constantly if you have
  // either in the array of dependencies. we should probably find a better way to do this here.

  return <></>;
}

RecentCookiesItemInjector.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['movie', 'gallery', 'model']).isRequired,
  siteUUID: PropTypes.string.isRequired,
  thumbnailCoverPath: PropTypes.string,
  headshotImagePath: PropTypes.string,
};

RecentCookiesItemInjector.defaultProps = {
  thumbnailCoverPath: '',
  headshotImagePath: '',
};
