import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { galleryCoverUrl } from 'src/client/helpers/urls';
import { formatViews } from 'src/client/utils/converters';
import ModelGridCard from '../Grid/Model/ModelGridCard';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { addFavor, removeFavor } from 'src/client/redux/modules/favoriteInfo';
import { show as showShareDialogFn } from 'src/client/redux/modules/shareDialog';
import { GridDetailedCardsWrapper } from '../GridDetailedCardsWrapper';
import ModelDetailedCard from '../Detailed/Model/ModelDetailedCard';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';

export default function PhotographerCards(props) {
  const dispatch = useDispatch();
  const favoriteInfo = useSelector((state) => state.favoriteInfo.favoritingObjectUUIDs);
  const contentCdnUrl = useSelector((state) => state.app.contentCdnUrl);
  const user = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.app.config);
  const view = useSettingsSelector('listView');
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const ratingInfo = useSelector((state) => state.ratingInfo.ratings);
  const generateJoinUrl = useJoinUrlGenerator();

  const isUser = user && user.validSubscription;

  const PhotographerCardComponent = view === 'detailed' ? ModelDetailedCard : ModelGridCard;

  const showShareDialog = (link: string, UUID: string) => dispatch(showShareDialogFn(link, 'photographer-card', UUID));
  const cards = props.photographers.map((photographer) => {
    const isFavorited = favoriteInfo.includes(photographer.UUID);

    let { views } = photographer;
    if (props.viewPeriod) {
      views = photographer.leaderboardViews[props.viewPeriod];
    }

    return (
      <PhotographerCardComponent
        key={photographer.UUID}
        name={photographer.name}
        url={photographer.path}
        imgSrc={
          photographer.coverImagePath && galleryCoverUrl(contentCdnUrl, photographer, config.cleanGalleryCovers, true)
        }
        imgAlt={photographer.name}
        ratingAverage={photographer.ratingAverage && parseFloat(photographer.ratingAverage).toFixed(ratingsScale)}
        isFavorited={favoriteInfo.includes(photographer.UUID)}
        views={formatViews(views)}
        galleriesCount={photographer.galleriesCount}
        moviesCount={photographer.moviesCount}
        onFavorite={
          isUser
            ? () => {
                const favorParams = {
                  objectUUID: photographer.UUID,
                  objectType: 'PHOTOGRAPHER',
                  // favoriteThis: !(decoration[gallery.type] && decoration[gallery.type][gallery.UUID]
                  //     && decoration[gallery.type][gallery.UUID].favorite),
                };

                if (isFavorited) {
                  dispatch(removeFavor(favorParams));
                } else {
                  dispatch(addFavor(favorParams));
                }
                // toggleFavor(favorParams);
              }
            : () => redirectToJoin(generateJoinUrl, 'favorite-photographer-card')
        }
        onShare={() => showShareDialog(photographer.path, photographer.name)}
        ratingStars={{
          userRating: ratingInfo[photographer.UUID],
          submitRating: isUser
            ? (rating) =>
                dispatch(
                  rateItem({
                    objectUUID: photographer.UUID,
                    objectType: 'PHOTOGRAPHER',
                    rating,
                  }),
                )
            : () => redirectToJoin(generateJoinUrl, 'rate-photographer-card'),
          hideLabel: true,
          rating: photographer.ratingAverage && parseFloat(photographer.ratingAverage).toFixed(ratingsScale),
        }}
      />
    );
  });

  const CardsComponent = props.useCardsComponent ? GridDetailedCardsWrapper : React.Fragment;

  return <CardsComponent>{cards}</CardsComponent>;
}

PhotographerCards.propTypes = {
  photographers: PropTypes.array.isRequired,
  useCardsComponent: PropTypes.bool,
  viewPeriod: PropTypes.string,
};

PhotographerCards.defaultProps = {
  useCardsComponent: true,
  viewPeriod: null,
};
