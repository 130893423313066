import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { HeaderContext, HeaderPopovers } from '../../containers/HeaderBar/HeaderProvider';
import { HoverMenuCaret, HoverMenuLink } from '../../containers/HeaderBar/styles';
import { useSelector } from '../../redux';
import { LiveGirlsPopover } from './LiveGirlsPopover';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { CamTrackingPosition } from 'src/client/constants/camTracking';

type Props = {
  shouldRender: boolean;
};

export const LiveGirlsMenu = (props: Props) => {
  const { activePopover, setActivePopover } = useContext(HeaderContext);
  const config = useSelector((state) => state.app.config);

  const camTrackingPosition: CamTrackingPosition = 'header';
  const generateCamUrlWithParams = useGenerateCamUrlWithParams({
    trackingPosition: camTrackingPosition,
    provider: config?.camProviders?.[0].provider ?? 'streamate',
  });

  const liveMenuOpen = activePopover === HeaderPopovers.LIVEGIRLS;
  const showLiveMenu = () => setActivePopover(HeaderPopovers.LIVEGIRLS);
  const hideLiveMenu = () => setActivePopover(null);

  if (!props.shouldRender) {
    return null;
  }

  const linkData =
    config.camsList?.length > 0
      ? {}
      : {
          href: generateCamUrlWithParams('/cams-list'),
          target: '_blank',
          rel: 'noopener noreferrer',
        };

  return (
    <MoreGirlsWrapper className="hidden-xs hidden-sm">
      <div onMouseOver={showLiveMenu} onFocus={showLiveMenu} onBlur={hideLiveMenu} onMouseLeave={hideLiveMenu}>
        <HoverMenuLink {...linkData}>
          <FormattedMessage id="header.links.liveGirls" defaultMessage="LIVE GIRLS" />
          <HoverMenuCaret icon={faCaretDown} size="lg" />
        </HoverMenuLink>
      </div>
      <LiveGirlsPopover
        camTrackingPosition={camTrackingPosition}
        liveMenuOpen={liveMenuOpen}
        showLiveMenu={showLiveMenu}
        hideLiveMenu={hideLiveMenu}
      />
    </MoreGirlsWrapper>
  );
};

const MoreGirlsWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
