import { take, call, fork, select, put } from 'redux-saga/effects';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/SpecialFeatures/LOAD';
const LOAD_SAGA = 'man-site/SpecialFeatures/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/SpecialFeatures/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/SpecialFeatures/LOAD_FAIL';

const endpoint = constructUrl(urls.get.specialFeatures);

interface InitialState {
  errorCategories: string[];
  errorCode: number | null;
  galleries: {};
  itemCounts: {};
  loadingCategories: string[];
  pages: {};
}

const initialState: InitialState = {
  errorCategories: [],
  errorCode: null,
  galleries: {},
  itemCounts: {},
  loadingCategories: [],
  pages: {},
};

interface ActionLoad {
  type: typeof LOAD;
  category: string;
  page: number;
}
interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  category: string;
  result: {
    galleries: {};
    total: number;
  };
  page: number;
}
interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  category: string;
  error: {
    status: number;
  };
}

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadSuccess | ActionLoadFail
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loadingCategories: [...state.loadingCategories, action.category],
        errorCategories: state.errorCategories.filter((category) => category !== action.category),
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        loadingCategories: state.loadingCategories.filter((category) => category !== action.category),
        galleries: {
          ...state.galleries,
          [action.category]: action.result.galleries,
        },
        itemCounts: {
          ...state.itemCounts,
          [action.category]: action.result.total,
        },
        pages: {
          ...(Object.keys(state.pages).length ? state.pages : {}),
          [action.category]: action.page,
        },
        errorCode: null,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loadingCategories: state.loadingCategories.filter((category) => category !== action.category),
        errorCategories: [...state.errorCategories, action.category],
        errorCode: action.error?.status,
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

function* loadGenerator(params) {
  const getState = (state) => state.specialFeatures;
  const currentState = yield select(getState);
  if (
    !currentState.loadingCategories.includes(params.category) &&
    currentState.pages[params.category] !== params.page
  ) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      category: params.category,
      page: params.page,
    });
    yield call(loadFunc);

    yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

    const newState = yield select();
    if (newState.auth.user && newState.specialFeatures.galleries[params.category]?.length > 0) {
      const objectUUIDs = newState.specialFeatures.galleries[params.category].map((item) => item.UUID);
      yield put(
        loadRatingInfo({
          objectUUIDs,
        })
      );
      yield put(
        loadFavoriteInfo({
          objectUUIDs,
        })
      );
    }
  }
}

function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
