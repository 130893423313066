import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import Modal from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { ToastsStore } from 'react-toasts';
import { faFacebookSquare, faTwitterSquare, faTumblrSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'src/client/redux';
import { useDispatch } from 'react-redux';
import { hide } from 'src/client/redux/modules/shareDialog';

const ShareDialogModal = styled(Modal)`
  &__share-modal {
    height: 100%;
    width: 100%;

    @media (min-width: 800px) {
      width: 375px;
      height: 235px;
    }
  }

  &__share-modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Title = styled.span`
  font-size: 2.15rem;
  display: block;
  margin-bottom: 10px;

  @media screen and (max-width: 370px) {
    text-align: center;
  }
`;

const Description = styled.span`
  display: block;
  margin: 12px 0 15px;
  font-size: 0.9rem;
  color: #000;

  @media screen and (max-width: 370px) {
    text-align: center;
  }
`;

const ShareIcons = styled.ul`
  text-align: center;
  li {
    font-size: 3.6rem;
  }

  @media screen and (max-width: 370px) {
    text-align: center;

    li {
      width: 100px;
      margin-bottom: 10px;

      :nth-child(odd) {
        text-align: right;
      }

      :nth-child(even) {
        text-align: left;
      }
    }
  }
`;

const FacebookIcon = styled(FontAwesomeIcon)`
  color: #3b5998;
`;

const TwitterIcon = styled(FontAwesomeIcon)`
  color: #00acee;
`;

const TumblrIcon = styled(FontAwesomeIcon)`
  color: #34526f;
`;

const EmailIcon = styled(FontAwesomeIcon)`
  color: #d79100;
`;

export const ShareDialog = () => {
  const site = useSelector((state) => state.site);
  const shareDialogState = useSelector((state) => state.shareDialog);
  const { siteSubdomain } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const link = `https://${siteSubdomain}.${site.domain}${shareDialogState.link}`;

  const fireEvent = useCallback(
    (method: string) => {
      window.dataLayer.push({
        event: 'share',
        content_type: shareDialogState.contentType,
        content_id: shareDialogState.contentID,
        method,
      });
    },
    [shareDialogState],
  );

  const copyToClipboard = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      (e.target as HTMLInputElement).select();
      document.execCommand('copy');
      ToastsStore.info('Copied to clipboard');
      fireEvent('clipboard');
    },
    [fireEvent],
  );

  return (
    <ShareDialogModal show={shareDialogState.isOpen} onClose={() => dispatch(hide())}>
      <Title>Share It</Title>
      <Description>Share by selecting your favorite social network</Description>
      <ShareIcons className="list-unstyled list-inline">
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${link}&utm_source=share-dialog&utm_medium=facebook`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => fireEvent('facebook')}
          >
            <FacebookIcon icon={faFacebookSquare} />
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?url=${link}&utm_source=share-dialog&utm_medium=twitter`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => fireEvent('twitter')}
          >
            <TwitterIcon icon={faTwitterSquare} />
          </a>
        </li>
        <li>
          <a
            href={`https://www.tumblr.com/share/link?url=${link}&utm_source=share-dialog&utm_medium=tumblr`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => fireEvent('tumblr')}
          >
            <TumblrIcon icon={faTumblrSquare} className="tumblr" />
          </a>
        </li>
        <li>
          <a
            href={`mailto:?subject=Shared%20from%20${site.name}&body=${link}&utm_source=share-dialog&utm_medium=email`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => fireEvent('email')}
          >
            <EmailIcon icon={faEnvelopeSquare} />
          </a>
        </li>
      </ShareIcons>
      <input
        type="text"
        className="form-control input-lg"
        value={`${link}?utm_source=share-dialog&utm_medium=clipboard`}
        readOnly
        onClick={copyToClipboard}
      />
    </ShareDialogModal>
  );
};
