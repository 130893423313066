import { useEffect, useState } from 'react';
import { usePromotions } from 'src/client/helpers/usePromotions';

export const usePromoPopup = (isPopupAllowedOnPage: boolean, isGatewayOpened: boolean) => {
  const { popupClickCount } = usePromotions();
  const [userClickCount, setUserClickCount] = useState<number>(0);

  const maxClickCount = popupClickCount ?? 3;

  useEffect(() => {
    const onClick = () => {
      if (isPopupAllowedOnPage && !isGatewayOpened && userClickCount < maxClickCount) {
        setUserClickCount(userClickCount + 1);
      }
    };

    document.body.addEventListener('click', onClick);

    return () => document.body.removeEventListener('click', onClick);
  }, [isGatewayOpened, maxClickCount, isPopupAllowedOnPage, setUserClickCount, userClickCount]);

  // based on the above check, userClickCount should never be set to a value over the maxClickCount
  return userClickCount === maxClickCount;
};
