import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FormattedDate, useIntl } from 'react-intl';
import { MediaUpdatesSection } from '../../../components/splash/MediaUpdatesSection/MediaUpdatesSection';
import RecentBlogSection from '../../../components/splash/RecentBlogSection/RecentBlogSection';
import SecurityIconsSection from '../../../components/splash/SecurityIconsSection/SecurityIconsSection';
import TaglineSection from '../../../components/splash/TagLineSection/TagLineSection';
import LastChanceSection from '../../../components/splash/LastChanceSection/LastChanceSection';
import { TopModelsSection } from '../../../components/splash/TopModelsSection/TopModelsSection';
import { AwardsSection } from 'src/client/components/splash/AwardsSection/AwardsSection';
import Testimonials from '../../../components/splash/Testimonials/Testimonials';
import { SecuritySection } from '../../../components/splash/SecuritySection/SecuritySection';
import TextBlockSection from '../TextBlockSection/TextBlockSection';
import SpecialFeaturesSection from '../SpecialFeaturesSection/SpecialFeaturesSection';
import EmailSignupSection from '../EmailSignupSection/EmailSignupSection';
import UpdatesSlider from '../../Updates/UpdatesSlider/UpdatesSlider';
import { MediaSection } from '../MediaSection/MediaSection';
import { SplashSlider } from '../Slider/SplashSlider';
import FooterBar from '../../FooterBar/FooterBar';
import { load } from '../../../redux/modules/splash';
import { galleryCoverUrl, modelHeadshotUrl } from 'src/client/helpers/urls';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from '../../../redux';
import Helmet from '../../Helmet/Helmet';
import VisibilitySensor from 'react-visibility-sensor';
import { PromoFooterBanner } from 'src/client/containers/Promo/PromoFooterBanner';
import { useInfinityTagEvent } from '../../../helpers/useInfinityTagEvents';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { PromoBanner } from 'src/client/containers/Promo/PromoBanner';
import { PlayboyBannerImage } from 'src/client/containers/Updates/PlayboyBannerImage';
import { usePlayboyLandingPage } from 'src/client/helpers/usePlayboyLandingPage';

const Div = styled.div<{
  promotionsActive?: boolean;
}>`
  color: ${(props) => props.theme.splashPrimary2};
  background-color: ${(props) => props.theme.splashPrimary1};

  a {
    color: ${(props) => props.theme.splashPrimary2};
    &:hover,
    :focus {
      color: ${(props) => props.theme.splashPrimary2};
    }
  }

  & .wrapper {
    position: relative;
    z-index: 0;
    background: ${(props) => props.theme.splashPrimary4};
    ${({ promotionsActive }) => promotionsActive && 'z-index: 1'};
  }

  & .updates-slider {
    > .row.container {
      margin: 0 auto;
    }
  }

  & section {
    padding: 20px 0 10px;
  }

  & a {
    text-decoration: none;
  }

  & a.view-all-link {
    text-transform: uppercase;
    display: block;
    line-height: 32px;
    padding-right: 15px;
  }

  & .record-disclaimers-wrapper {
    font-size: 0.8em;
    padding-bottom: 20px;
  }

  & section {
    background-color: ${(props) => props.theme.splashPrimary1};
  }

  & a.view-all-link {
    color: ${(props) => props.theme.splashPrimary3};
  }

  & .security-features-description {
    color: ${(props) => props.theme.splashPrimary3};
  }

  & .record-disclaimers-wrapper {
    color: ${(props) => props.theme.splashPrimary3};
  }

  & .item {
    background: transparent;
  }

  .splash-mobile ins > a > img,
  .splash-tablet ins > a > img,
  .splash-hero ins > a > img {
    width: 100%;
    height: auto;
  }
  .splash-hero ins > a > img {
    max-width: 2162px;
  }
`;

export default function Splash() {
  const [isSplashAdVisible, setSplashAdVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const sendMainPageEventOnLoad = useInfinityTagEvent({
    name: 'Main page',
    type: 'Inquiry',
    value: '0.001',
  });

  const { movieUpdates, galleryUpdates, models, freeGalleries, videoLoop, testimonials, blog } = useSelector(
    (state) => state.splash,
  );
  const { user, loaded: userLoaded } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);
  const {
    contentCdnUrl,
    cdnUrl,
    config: { splash, hasWideMovieCovers, description: tagline, cleanGalleryCovers, imgPlaceholderPath },
  } = useSelector((state) => state.app);

  const guestsOnly = splash?.guestsOnly;
  const components = splash?.components;

  const measurements = useSettingsSelector<'imperial' | 'metric'>('measurements');
  const mainPageUrl = useSettingsSelector('homepage');

  const { isPromoBannerActive } = usePromotions();
  const showPlayboyBanner = usePlayboyLandingPage();

  // sending infinity tag event when user loads splash, this might have to change in the future
  // to support different sites since some have different home page routes.
  useEffect(() => {
    if (!user && userLoaded) {
      sendMainPageEventOnLoad();
    }
  }, [sendMainPageEventOnLoad, user, userLoaded]);

  useEffect(() => {
    if (!user || !guestsOnly) {
      dispatch(load());
    }
  }, [dispatch, user, guestsOnly]);
  const [showSlider, setSliderVisibility] = useState<boolean>(false);

  const { search } = useLocation();
  const freetrial = new URLSearchParams(search).get('freetrial');

  useEffect(() => {
    setSliderVisibility(!!components?.sliderComponent?.show);
    if (freetrial === '1') {
      setSliderVisibility(false);
    }
  }, [setSliderVisibility, components, freetrial]);

  if (user && (guestsOnly || mainPageUrl !== 'splash')) {
    return <Redirect to={redirectHomepages[mainPageUrl]} />;
  }

  const showMediaSection = Boolean(components?.mediaSection);
  const showTaglineSection = Boolean(components?.taglineSection);
  const showSecuritySection = Boolean(components?.securitySection);
  const showSecurityIconsSection = Boolean(components?.securityIconsSection);
  const showLastChanceSection = Boolean(components?.lastChanceSection);
  const showTestimonials = Boolean(components?.testimonials);
  const showSpecialFeaturesSection = Boolean(components?.specialFeaturesSection);
  const showUpdatesSection = Boolean(components?.updatesSection);
  const showRecentBlogSection = Boolean(components?.recentBlogSection);
  const showTextBlockSection = Boolean(components?.textBlockSection);
  const galleryPlaceholderImgSrc = `${contentCdnUrl}/${imgPlaceholderPath.gallery}`;
  const moviePlaceholderImgSrc = `${contentCdnUrl}/${imgPlaceholderPath.movie}`;
  const modelPlaceholderImgSrc = `${contentCdnUrl}/${imgPlaceholderPath.model}`;
  // get top model and runner up in one variable for models in mediaSection
  const mediaSectionModels = models.runnersUp.map((model) => ({
    ...model,
    imgSrc: modelHeadshotUrl(contentCdnUrl, model, model.siteUUID),
  }));

  if (mediaSectionModels.length > 0 && models.topModel) {
    mediaSectionModels.unshift({
      ...models.topModel,
      imgSrc: modelHeadshotUrl(contentCdnUrl, models.topModel, models.topModel?.siteUUID),
    });
  }

  return (
    <Div>
      <Helmet
        id="homepage"
        variables={{
          siteDomain: site.domain,
        }}
      >
        <meta property="prerender:ttl" content="86400" />
      </Helmet>
      <div className="wrapper">
        {showPlayboyBanner && <PlayboyBannerImage />}
        {isPromoBannerActive && !showPlayboyBanner && (
          <VisibilitySensor onChange={setSplashAdVisible} partialVisibility>
            <PromoBanner />
          </VisibilitySensor>
        )}
        {!isPromoBannerActive && !showPlayboyBanner && (
          <SplashSlider show={showSlider} teasers={freeGalleries} hideSlider={() => setSliderVisibility(false)} />
        )}
        {!isPromoBannerActive && !showPlayboyBanner && (
          <EmailSignupSection
            show={!showSlider && !showUpdatesSection}
            freeGalleries={freeGalleries}
            videoLoop={videoLoop}
          />
        )}
        {!isPromoBannerActive && showUpdatesSection && (
          <div className="updates-slider">
            <UpdatesSlider {...components?.props?.updatesSlider} />
          </div>
        )}

        <TaglineSection show={showTaglineSection} tagline={tagline} />

        {showSpecialFeaturesSection && <SpecialFeaturesSection hideSignUp={user?.validSubscription} />}

        <MediaSection
          models={mediaSectionModels}
          galleries={galleryUpdates}
          movies={movieUpdates}
          show={showMediaSection}
        />

        <AwardsSection show={showMediaSection} />

        {/*  Movies  */}
        <MediaUpdatesSection
          show={movieUpdates.length > 0 && !showMediaSection}
          title={intl.formatMessage({ id: 'splash.mediaUpdates.movie.headline', defaultMessage: 'MOVIES UPDATES' })}
          items={movieUpdates.map((movie) => ({
            UUID: movie.UUID,
            imgSrc: movie.hasCover
              ? galleryCoverUrl(contentCdnUrl, movie, cleanGalleryCovers, true, !!hasWideMovieCovers)
              : moviePlaceholderImgSrc,
            name: movie.name,
            url: movie.path,
            nameLink: { text: movie.models.map((model) => model.name).join(', '), url: movie.path },
            timestamp: <FormattedDate value={movie.publishedAt} day="2-digit" month="long" year="numeric" />,
          }))}
          arrowLinkLabel={intl.formatMessage({
            id: 'splash.mediaUpdates.movie.arrowLink',
            defaultMessage: 'View All Movies',
          })}
          arrowLinkUrl="/erotic-films"
          bottomLinkLabel={intl.formatMessage({
            id: 'splash.mediaUpdates.movie.bottomLink',
            defaultMessage: 'VIEW ALL MOVIES',
          })}
          bottomLinkUrl="/erotic-films"
        />

        {(!user || !user.validSubscription) && (
          <Testimonials show={!!(testimonials.length && showTestimonials)} content={testimonials} />
        )}

        {showRecentBlogSection && blog && Object.keys(blog).length > 0 && (
          <RecentBlogSection blog={blog} title={components?.props?.recentBlog?.title} />
        )}

        {models && models.runnersUp[0] && models.topModel && (
          <TopModelsSection
            show={!showMediaSection}
            topModel={{
              ...models.topModel,
              imgSrc: modelHeadshotUrl(contentCdnUrl, models.topModel, models.topModel.siteUUID),
              country:
                models.topModel.country && models.topModel.country.name.toLowerCase() !== 'unknown'
                  ? models.topModel.country
                  : null,
              ratingAverage: models.topModel.ratingAverage !== 0 ? models.topModel.ratingAverage : null,
            }}
            galleryCount={models.topModel.galleriesCount}
            movieCount={models.topModel.moviesCount}
            runnersUp={models.runnersUp.map((runnerUp) => ({
              ...runnerUp,
              imgSrc: modelHeadshotUrl(contentCdnUrl, runnerUp, runnerUp.siteUUID),
            }))}
            recentContentQuery={models.topModel.galleries.map((gallery) => {
              const wideThumbnail = hasWideMovieCovers && gallery.type === 'MOVIE';
              return {
                ...gallery,
                coverUrl: gallery.hasCover
                  ? galleryCoverUrl(contentCdnUrl, gallery, false, true, wideThumbnail)
                  : modelPlaceholderImgSrc,
              };
            })}
            measurements={measurements}
          />
        )}

        {/*  Galleries */}
        <MediaUpdatesSection
          show={galleryUpdates.length > 0 && !showMediaSection}
          title={intl.formatMessage({
            id: 'splash.mediaUpdates.photos.headline',
            defaultMessage: 'PHOTOGRAPHY UPDATES',
          })}
          items={galleryUpdates.map((gallery) => {
            const wideThumbnail = hasWideMovieCovers && gallery.type === 'MOVIE';
            return {
              UUID: gallery.UUID,
              imgSrc: gallery.hasCover
                ? galleryCoverUrl(contentCdnUrl, gallery, cleanGalleryCovers, true, wideThumbnail)
                : galleryPlaceholderImgSrc,
              name: gallery.name,
              url: gallery.path,
              nameLink: { text: gallery.models.map((model) => model.name).join(', '), url: gallery.path },
              timestamp: <FormattedDate value={gallery.publishedAt} day="2-digit" month="long" year="numeric" />,
            };
          })}
          arrowLinkLabel={intl.formatMessage({
            id: 'splash.mediaUpdates.photos.arrowLink',
            defaultMessage: 'View All Photos',
          })}
          arrowLinkUrl="/galleries"
          bottomLinkLabel={intl.formatMessage({
            id: 'splash.mediaUpdates.photos.bottomLink',
            defaultMessage: 'VIEW ALL PHOTOS',
          })}
          bottomLinkUrl="/galleries"
        />

        <SecuritySection show={showSecuritySection} />

        {(!user || !user.validSubscription) && showTextBlockSection && <TextBlockSection />}

        {(!user || !user.validSubscription) && (
          <LastChanceSection
            discoverMoreLink={redirectHomepages[mainPageUrl]}
            show={showLastChanceSection}
            site={site}
          />
        )}
        {(!user || !user.validSubscription) && (
          <SecurityIconsSection
            show={showSecurityIconsSection}
            items={[
              {
                imageUrl: `${cdnUrl}/checkout/visa@2x.png`,
                imageAlt: 'Visa',
                linkUrl: '',
              },
              {
                imageUrl: `${cdnUrl}/checkout/mastercard@2x.png`,
                imageAlt: 'Mastercard',
                linkUrl: '',
              },
              {
                imageUrl: `${cdnUrl}/checkout/paypal@2x.png`,
                imageAlt: 'Paypal by Epoch',
                linkUrl: '',
              },
              {
                imageUrl: `${cdnUrl}/checkout/bitcoin@2x.png`,
                imageAlt: 'Bitcoin',
                linkUrl: '',
              },
              {
                imageUrl: `${cdnUrl}/checkout/comodo@2x.png`,
                imageAlt: 'Comodo Secure',
                linkUrl: `https://www.ssllabs.com/ssltest/analyze.html?d=secure.${site.domain}`,
              },
              {
                imageUrl: `${cdnUrl}/checkout/norton@2x.png`,
                imageAlt: 'Norton Secured',
                linkUrl: '',
              },
            ]}
          />
        )}
        {isPromoBannerActive && !isSplashAdVisible && <PromoFooterBanner />}
      </div>
      <FooterBar />
    </Div>
  );
}
