import { take, call, fork, select, cancel, put } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import urls, { constructUrl } from 'src/shared/urls';
import { getRequestFunc } from 'src/client/helpers';
import { addDashesToUUID } from 'src/shared/converters';

const LOAD_SAGA = 'man-site/playedMovies/LOAD_SAGA';
const LOAD = 'man-site/playedMovies/LOAD';
const LOAD_SUCCESS = 'man-site/playedMovies/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/playedMovies/LOAD_FAIL';
const ADD_LOADING_QUEUE = 'man-site/playedMovies/ADD_LOADING_QUEUE';
const PLAY_SAGA = 'man-site/playedMovies/PLAY_SAGA';
const PLAY = 'man-site/playedMovies/PLAY';
const PLAY_SUCCESS = 'man-site/playedMovies/PLAY_SUCCESS';
const PLAY_FAIL = 'man-site/playedMovies/PLAY_FAIL';

const getEndpoint = constructUrl(urls.post.playedMoviesInfo);
const setEndpoint = constructUrl(urls.post.playedMovie);

interface InitialState {
  items: string[];
  loadingItems: string[];
  loadedItems: string[];
  error?: ResponseError;
}

const initialState: InitialState = {
  items: [],
  loadingItems: [],
  loadedItems: [],
};

interface ActionLoad {
  type: typeof LOAD;
  galleryUUIDs: string[];
}
interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  galleryUUIDs: string[];
  result: string[];
}
interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  galleryUUIDs: string[];
  error: ResponseError;
}

interface ActionAddLoadingQueue {
  type: typeof ADD_LOADING_QUEUE;
  galleryUUIDs: string[];
}
interface ActionPlaySuccess {
  type: typeof PLAY_SUCCESS;
  galleryUUID: string;
}

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadSuccess | ActionLoadFail | ActionAddLoadingQueue | ActionPlaySuccess
): InitialState {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        items: [...new Set([...state.items, ...action.result])],
        loadedItems: [...new Set([...state.loadedItems, ...action.galleryUUIDs])],
        loadingItems: state.loadingItems.filter((UUID) => !action.galleryUUIDs.includes(UUID)),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loadingItems: state.loadingItems.filter((UUID) => !action.galleryUUIDs.includes(UUID)),
        loadedItems: [...new Set([...state.loadedItems, ...action.galleryUUIDs])],
      };
    case ADD_LOADING_QUEUE: {
      return {
        ...state,
        loadingItems: [...state.loadingItems, ...action.galleryUUIDs],
      };
    }
    case PLAY_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.galleryUUID],
      };
    default: {
      return state;
    }
  }
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function sendPlayedMovie(params) {
  return {
    type: PLAY_SAGA,
    params,
  };
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const getState = (state) => state.playedMovies;
/* SAGAS */
function* loadGenerator(params) {
  let currentState = yield select(getState);

  const galleryUUIDs: string[] = [];

  params.galleryUUIDs.forEach((galleryUUID) => {
    if (!currentState.loadingItems.includes(galleryUUID) && !currentState.loadedItems.includes(galleryUUID)) {
      galleryUUIDs.push(galleryUUID);
    }
  });

  yield put({
    type: ADD_LOADING_QUEUE,
    galleryUUIDs,
  });

  yield delay(1250);

  currentState = yield select(getState);

  if (currentState.loadingItems.length === 0) {
    return;
  }

  const loadFunc = getRequestFunc(
    [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    (client) =>
      client.post(getEndpoint, {
        data: {
          galleryUUIDs: currentState.loadingItems,
        },
      }),
    { galleryUUIDs: currentState.loadingItems }
  );
  yield call(loadFunc);
}

function* playGenerator(params) {
  const { items } = yield select(getState);
  const { galleryUUID } = params;

  if (!items.includes(addDashesToUUID(galleryUUID))) {
    const playFunc = getRequestFunc(
      [PLAY, PLAY_SUCCESS, PLAY_FAIL],
      (client) =>
        client.post(setEndpoint, {
          data: {
            ...params,
          },
        }),
      {
        galleryUUID,
      }
    );

    yield call(playFunc);
  }
}

// Trigger
function* watchLoad() {
  let currentSaga;
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    const { loading } = yield select(getState);
    if (!loading) {
      if (currentSaga) {
        yield cancel(currentSaga);
      }
      currentSaga = yield fork(loadGenerator, params);
    }
  }
}

function* watchPlay() {
  while (true) {
    const { params } = yield take(PLAY_SAGA);
    yield call(playGenerator, params);
  }
}

export const watchers = [fork(watchLoad), fork(watchPlay)];
/* EOF SAGAS */
