import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import PostButton from '@tovia/man-ui/lib/components/Button/Button';
import { DisplayNameField } from './DisplayNameField';
import { useDisplayName } from './useDisplayName';
import { useSelector } from '../../redux/modules/helpers/useSelector';

type Props = {
  displayName: string;
  setDisplayName: (name: string) => Promise<{ success: boolean; reason: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const CommentForm = (props: Props) => {
  const { displayName } = props;
  const displayNameFieldRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const {
    newDisplayName,
    setNewDisplayName,
    displayNameError,
    displayNameStatus,
    setDisplayNameError,
  } = useDisplayName();
  const [saveDisplayNameError, setSaveDisplayNameError] = useState<boolean>(false);
  const [displayNameValidationError, setDisplayNameValidationError] = useState<null | string>(null);

  const { submitting, postStatus } = props;
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [comment, setComment] = useState('');
  const postError = useSelector((state) => state.comments.postError?.response?.body);

  useEffect(() => {
    if (postStatus === 'success' || postStatus === 'fail') {
      setIsSubmittingComment(false);
    }
    if (postStatus === 'success') {
      setComment('');
    }
  }, [submitting, postStatus]);

  const startFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pristine) return;

    if (!newDisplayName || !displayName) {
      if (!displayName && !displayNameFieldRef.current?.value) {
        displayNameFieldRef.current?.focus();
        setDisplayNameError('You must choose a name to post comments.');
        return;
      }
    }

    setIsSubmittingComment(true);
    setDisplayNameValidationError(null);

    if (!displayName) {
      setSaveDisplayNameError(false);
      const { success, reason } = await props.setDisplayName(newDisplayName);
      if (!success) {
        setSaveDisplayNameError(true);
        setIsSubmittingComment(false);
        if (reason) {
          setDisplayNameValidationError(reason);
        }
        return;
      }
    }

    props.onSubmit({ 'new-comment-message': comment });
  };

  const hasBannedWord = !!postError?.bannedWord;
  const pristine = !comment;
  const genericError = !submitting && (postStatus === 'fail' || saveDisplayNameError) && !hasBannedWord;

  return (
    <CommentFormWrapper>
      <fieldset disabled={submitting || postStatus === 'loading'}>
        <form onSubmit={startFormSubmit}>
          <DisplayNameField
            newDisplayName={newDisplayName}
            setNewDisplayName={setNewDisplayName}
            displayNameError={displayNameError}
            displayNameStatus={displayNameStatus}
            displayNameFieldRef={displayNameFieldRef}
            displayNameValidationError={displayNameValidationError}
          />

          <TextAreaWrapper className="form-group">
            <textarea
              name="new-comment-message"
              rows={5}
              className="form-control"
              placeholder={intl.formatMessage({
                id: 'comments.form.placeholder',
                defaultMessage: 'Enter your comment',
              })}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </TextAreaWrapper>

          <div className="error-msg" hidden={!genericError}>
            {intl.formatMessage({
              id: 'comments.form.error_message',
              defaultMessage: 'There was a problem submitting your comment',
            })}
          </div>

          <div className="error-msg" hidden={!hasBannedWord}>
            {intl.formatMessage(
              {
                id: 'comments.form.banned_word_message',
                defaultMessage:
                  'The following word or phrase "{bannedWord}" may not be used while commenting. Thank you for your understanding',
              },
              { bannedWord: postError?.bannedWord?.trim() },
            )}
          </div>

          <div className="form-group submit-wrapper">
            <SubmitButton
              type="submit"
              loading={isSubmittingComment}
              disabled={pristine}
              className="post-btn btn btn-primary"
              spinnerClass="post-spinner"
            >
              {intl.formatMessage({ id: 'comments.buttons.post', defaultMessage: 'Post Comment' })}
            </SubmitButton>
          </div>
        </form>
      </fieldset>
    </CommentFormWrapper>
  );
};

const CommentFormWrapper = styled.div`
  margin-top: 0;
  max-width: 635px;

  & .error-msg {
    color: red;
  }

  & .submit-wrapper {
    margin-top: 15px;
    margin-bottom: 0;
  }

  & .username {
    max-width: 240px;
    border: 1px solid #eee;
    background-color: #ffffff;
    color: #000000;
  }

  & .no-break {
    white-space: nowrap;
  }
`;

const TextAreaWrapper = styled.div`
  margin-bottom: 0;

  textarea {
    border-radius: 6px;
    font-size: 0.9rem;
    max-width: 635px;
  }
`;

const SubmitButton = styled(PostButton)`
  position: relative;

  .post-spinner {
    height: 22px;

    .spinner {
      position: absolute;
      width: 20px;
      height: 20px;

      path {
        fill: #888888;
      }
    }
  }

  &.loading {
    background-color: #9d9d9d;
    color: transparent;
  }

  &[disabled] {
    background-color: #9d9d9d;
    border-color: transparent;
  }
`;
