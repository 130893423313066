import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
type Props = {
  className?: string;
  name: string;
  path?: string;
  imgSrc: string;
};

export const SplashModelCard = (props: Props) => {
  const { name, imgSrc, path } = props;
  return (
    <CardWrapper>
      <CardBackground imgSrc={imgSrc} />
      <CardOverlay />
      <CardTitle className="card-title">{name}</CardTitle>
      {path ? <CardLink to={path} /> : null}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  float: left;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  margin: 0;
  background: transparent !important;
  aspect-ratio: 100 / 150;

  img {
    border-radius: 0;
  }
`;

const CardBackground = styled.div<{ imgSrc: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.imgSrc});
`;

const CardOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  display: inline-block;
  background: #000;
  opacity: 0;
  cursor: pointer;

  @media ${TABLET_PORTRAIT} {
    ${CardWrapper}:hover & {
      opacity: 0.5;
    }
  }
`;

const CardTitle = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  opacity: 0;
  font-size: 1.07rem;
  font-weight: 700;
  display: block;
  bottom: 10%;
  line-height: 15px;

  @media ${TABLET_PORTRAIT} {
    ${CardWrapper}:hover & {
      opacity: 1;
    }
  }
`;

const CardLink = styled(NavLink)`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
`;
