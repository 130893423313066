import React, { ReactNode } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import { TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { StandardGrid } from 'src/client/components/grids';

type Props = {
  className?: string;
  children?: ReactNode;
};

export default function GridCards(props: Props) {
  return <GridCardsWrapper className={cx(props.className, 'grid-cards')}>{props.children}</GridCardsWrapper>;
}

const GridCardsWrapper = styled(StandardGrid)`
  margin: 20px 0;

  @media ${TABLET_LANDSCAPE} {
    row-gap: 25px;
  }

  .cams-wrapper {
    display: none;
    height: 100%;
    grid-row-start: 1;

    @media ${TABLET_PORTRAIT} {
      display: block;
      grid-column: -2 / span 1;
      grid-row-end: 3;

      .horizontal-covers & {
        grid-row-end: 4;
      }
    }

    @media ${TABLET_LANDSCAPE} {
      grid-column: -3 / span 2;
      grid-row-end: 5;

      .horizontal-covers & {
        grid-row-end: 7;
      }
    }
  }

  .cams-grid {
    @media ${TABLET_LANDSCAPE} {
      row-gap: 25px;
    }
  }
`;
