import { faSpiderBlackWidow, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import cx from 'classnames';
import { useCookies } from 'react-cookie';
import { debugItems, DebugValues, METART_DEBUG_COOKIE } from 'src/@types/DebugValues';
import styled from 'styled-components/macro';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any;
};

export const DebugSettingsManager: React.FC<Props> = (props) => {
  const [popupVisible, setPopupVisible] = React.useState(false);

  const [cookies, setCookie] = useCookies([METART_DEBUG_COOKIE]);
  const [debugValues, setDebugValues] = React.useState<DebugValues>(() => cookies[METART_DEBUG_COOKIE] || {});

  const setDebugCookie = (value) =>
    setCookie(METART_DEBUG_COOKIE, value, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      domain: props.initialState?.site.domain,
    });

  const saveValues = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const data = [...new FormData(form).entries()].reduce(
      (prev, [key, value]) => ({ ...prev, [key]: value }),
      {} as DebugValues,
    );

    setDebugValues(data);
    setDebugCookie(data);
  };

  const clearValue = (path) => {
    const newValues = { ...debugValues };
    delete newValues[path];
    setDebugValues(newValues);
  };

  return (
    <>
      <DebugClicker onClick={() => setPopupVisible(!popupVisible)} hidden={popupVisible}>
        <FontAwesomeIcon icon={faSpiderBlackWidow} />
      </DebugClicker>

      <DebugForm onSubmit={saveValues} hidden={!popupVisible}>
        <Line />
        {Object.entries(debugItems).map(([path, type]) => {
          const FormComponent = formComponentsMap[type];

          return (
            <Fragment key={path}>
              <FormComponent clearValue={clearValue} defaultValue={debugValues[path]} path={path} type={type} />
              <Line />
            </Fragment>
          );
        })}
        <Navbar>
          <Button type="button" className="cancel" onClick={() => setPopupVisible(false)}>
            Close
          </Button>
          <Button type="reset" className="reset">
            Reset All
          </Button>
          <Button type="button" className="reload" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button type="submit" className="save">
            Save
          </Button>
        </Navbar>
      </DebugForm>
    </>
  );
};

type FormItemProps<T = string | boolean> = {
  path: string;
  clearValue: (path: string) => void;
  type: string;
  defaultValue?: T;
};

const DebugFormCheckbox = (props: FormItemProps<boolean>) => {
  const [checked, setChecked] = React.useState(props.defaultValue);
  const { path } = props;
  return (
    <>
      <label className="full" htmlFor={path}>
        {path}
        <input name={path} type={props.type} checked={checked} onChange={(e) => setChecked(e.target.checked)} />
      </label>
      <FontAwesomeIcon icon={faUndo} onClick={() => props.clearValue(path)} className={cx({ active: checked })} />
    </>
  );
};

const DebugFormTextbox = (props: FormItemProps<string>) => {
  const [value, setValue] = React.useState(props.defaultValue || '');
  const { path } = props;

  const reset = () => {
    props.clearValue(path);
    setValue('');
  };

  return (
    <>
      <label htmlFor={path}>{path}</label>
      <input name={path} type={props.type} value={value} onChange={(e) => setValue(e.target.value)} />
      <FontAwesomeIcon icon={faUndo} onClick={reset} className={cx({ active: value })} />
    </>
  );
};

const formComponentsMap = {
  checkbox: DebugFormCheckbox,
  text: DebugFormTextbox,
};

const DebugClicker = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2000;
  font-size: 30px;
  line-height: 1;
  color: lightpink;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;

  && svg {
    display: block;
    width: 30px;
  }
`;

const DebugForm = styled.form`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  margin: 0;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.9);
  display: ${({ hidden }) => (hidden ? 'none' : 'grid')};
  grid-template-columns: 400px 150px 25px;

  svg {
    align-self: center;
    justify-self: center;
    color: red;
    opacity: 0.2;

    &.active {
      opacity: 1;
    }
  }

  input,
  label {
    display: block;
    margin: 0;
    padding: 5px 0;
  }

  input {
    justify-self: right;

    &[type='text'] {
      background: rgba(200, 200, 200, 0.5);
      padding: 0 10px;
      width: 150px;
      border: none;
      text-align: right;
    }

    &[type='checkbox'] {
      margin-left: 1em;
    }
  }

  label {
    &.full {
      grid-column: 1 / 3;
      display: flex;
      justify-content: space-between;
    }
  }
`;

const Line = styled.hr`
  grid-column: 1 / 4;
  border: none;
  margin: 0;
  border-bottom: 0.5px solid black;
`;

const Button = styled.button`
  &.cancel {
    margin-right: 10px;
  }

  &.reload {
    margin-left: auto;
    margin-right: 10px;
  }
`;

const Navbar = styled.nav`
  margin-top: 20px;
  grid-column: 1 / 4;
  display: flex;
  flex-direction: row;
`;
