import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import CardMedia from '@tovia/man-ui/lib/components/Card/CardMedia';
import CardInformation from '@tovia/man-ui/lib/components/Card/CardInformation';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import Slider from '../../components/sliders/Slider/Slider';
import { galleryCoverUrl } from 'src/client/helpers/urls';
import { useSelector } from '../../redux';
import {
  AffiliateCode,
  createAffiliateURL,
  getAffiliateCodeForRedirect,
} from '@tovia/man-app-utils/lib/helpers/affiliate';
import { addDashesToUUID } from 'src/shared/converters';
import { GlobalContent } from 'src/@types/app';

const Div = styled.div`
  .item-slide {
    padding: 0;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }

  //.item-name {
  //  white-space: nowrap;
  //  text-overflow: ellipsis;
  //  text-decoration: none;
  //  overflow: hidden;
  //  display: block;
  //}

  .card {
    margin: 0 5px;
  }
`;

const settings = {
  slidesToScroll: 1,
  draggable: true,
  responsive: [
    {
      breakpoint: 515,
      settings: { slidesToShow: 1 },
    },
    {
      breakpoint: 740,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 986,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 100000,
      settings: { slidesToShow: 5, slidesToScroll: 5 },
    },
  ],
};
type PropTypes = {
  galleries: GlobalContent[];
  className?: string;
  title?: string;
};
export default function NetworkGalleriesSlider(props: PropTypes) {
  const { galleries, className, title } = props;

  const sites = useSelector((state) => state.sites.items);
  const site = useSelector((state) => state.site);
  const { accessSubdomain, defaultCdnUrl, siteSubdomain } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const items = galleries.map((gallery) => {
    const gallerySite = sites.find((cSite) => cSite.UUID === gallery.siteUUID);
    const { domain } = gallerySite || site;

    let affiliateCode: AffiliateCode | undefined;
    if (user?.firstPurchase) {
      affiliateCode = getAffiliateCodeForRedirect(user.firstPurchase, addDashesToUUID(gallery.siteUUID));
    }

    const galleryURL = `https://${siteSubdomain}.${domain.toLowerCase()}${gallery.path}`;
    const galleryAffiliateURL = createAffiliateURL({
      accessSubdomain,
      affiliateCode,
      siteDomain: domain.toLowerCase(),
      redirect: galleryURL,
      siteSubdomain,
    });

    return {
      name: gallery.name,
      url: galleryAffiliateURL,
      siteName: gallerySite?.name,
      imgSrc: galleryCoverUrl(defaultCdnUrl, gallery, false, true),
    };
  });

  return (
    <Div>
      <Slider title={title} settings={settings} className={className}>
        {items.map((item) => (
          <a key={item.url} className="item-slide" href={item.url} target="_blank" rel="noopener noreferrer">
            <Card>
              <CardMedia imgSrc={item.imgSrc} altText={item.name} />
              <CardInformation className="card-info-1" title={item.name} subTitle={item.siteName} />
            </Card>
          </a>
        ))}
      </Slider>
    </Div>
  );
}

NetworkGalleriesSlider.propTypes = {
  galleries: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

NetworkGalleriesSlider.defaultProps = {
  className: '',
  title: 'More sets of our models in the MetArt Network',
};
