import React, { useContext } from 'react';
import { HoverMenuCaret, MenuNavLink } from '../../containers/HeaderBar/styles';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { HeaderContext, HeaderPopovers } from '../../containers/HeaderBar/HeaderProvider';
import { SeoCategoriesPopover } from './SeoCategoriesPopover';
import styled from 'styled-components/macro';
import { useSelector } from 'src/client/redux';

export const SeoCategoriesMenu = () => {
  const { activePopover, setActivePopover } = useContext(HeaderContext);
  const config = useSelector((state) => state.app.config);

  const menuIsOpen = activePopover === HeaderPopovers.SEO_CATEGORIES;
  const showMenu = () => setActivePopover(HeaderPopovers.SEO_CATEGORIES);
  const hideMenu = () => setActivePopover(null);
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!config.hasCategories) {
      e.preventDefault();
    }
  };

  return (
    <Wrapper className="hidden-xs hidden-sm">
      <MenuNavLink
        exact
        to={'/tags'}
        onMouseOver={showMenu}
        onFocus={showMenu}
        onBlur={hideMenu}
        onMouseLeave={hideMenu}
        onClick={onClick}
      >
        CATEGORIES
        <HoverMenuCaret icon={faCaretDown} size="lg" />
      </MenuNavLink>
      <SeoCategoriesPopover menuIsOpen={menuIsOpen} showMenu={showMenu} hideMenu={hideMenu} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;
