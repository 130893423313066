import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faImages } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp, faVideo } from '@fortawesome/pro-solid-svg-icons';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import Image from '@tovia/man-ui/lib/components/Image/Image';
import { Link } from 'react-router-dom';
import RatingOverlay from '@tovia/man-ui/lib/components/RatingOverlay/RatingOverlay';
import Badges from '@tovia/man-ui/lib/components/Badge/Badges';
import Badge from '@tovia/man-ui/lib/components/Badge/Badge';
import { ratingStarsShape } from '../../../../utils/props';

const Wrapper = styled.div`
  &:not(.menu-opened) {
    &:hover {
      .card-menu-overlay {
        opacity: 1;
      }
    }
  }

  .card-menu-overlay {
    transition: 0.3s opacity;
    opacity: 0;
    color: #fff;
  }

  .card-info-1 {
    padding-bottom: 0;
  }
  .card-info-2 {
    padding-top: 0;
  }

  .card-media {
    overflow: hidden;
  }

  //.item-name {
  //  white-space: nowrap;
  //  text-overflow: ellipsis;
  //  text-decoration: none;
  //  overflow: hidden;
  //  display: block;
  //}
`;

export default function ModelDetailedCard(props) {
  const { url, imgSrc, imgAlt, favoriteCount, views, name, ratingStars, galleriesCount, moviesCount } = props;

  const intl = useIntl();

  const usersRating =
    ratingStars.rating !== 0
      ? ratingStars.rating
      : intl.formatMessage({ id: 'detailedCard.notRated', defaultMessage: 'Not rated yet' });

  const attrs = [
    {
      label: ratingStars.userRating
        ? intl.formatMessage({ id: 'detailedCard.yourRating', defaultMessage: 'Your Rating' })
        : intl.formatMessage({ id: 'detailedCard.rating', defaultMessage: 'Rating' }),
      value: (
        <RatingOverlay ratingStars={ratingStars}>
          <span className="rating-overlay">{ratingStars.userRating ? ratingStars.userRating : usersRating}</span>
        </RatingOverlay>
      ),
    },
  ];

  return (
    <Wrapper
      className="detailed-card-wrapper"
      // ref={hoverRef}
    >
      <Card>
        <div className="photo">
          <Link to={url}>
            <Image src={imgSrc} alt={imgAlt} width="100%" height="100%" lazy animate />
          </Link>
        </div>
        <div className="photo-stats">
          <h3>
            <Link to={url}>{name}</Link>
          </h3>
          <div className="attrs">
            {attrs.map((attr) => (
              <div className="attr" key={attr.label}>
                <span className="attr-label">{attr.label}: </span>
                <span className="attr-value">{attr.value}</span>
              </div>
            ))}
          </div>
          <Badges>
            {galleriesCount > 0 && (
              <Badge key="photos" text={galleriesCount} icon={<FontAwesomeIcon icon={faImages} />} />
            )}
            {moviesCount > 0 && <Badge key="movies" text={moviesCount} icon={<FontAwesomeIcon icon={faVideo} />} />}
            <Badge key="views" text={views} icon={<FontAwesomeIcon icon={faEye} />} />
            <Badge key="favorites" text={favoriteCount} icon={<FontAwesomeIcon icon={faThumbsUp} />} />
          </Badges>
        </div>
      </Card>
    </Wrapper>
  );
}

ModelDetailedCard.propTypes = {
  url: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  favoriteCount: PropTypes.number,
  views: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  galleriesCount: PropTypes.number,
  moviesCount: PropTypes.number,
  ratingStars: ratingStarsShape.isRequired,
  name: PropTypes.string.isRequired,
};

ModelDetailedCard.defaultProps = {
  favoriteCount: 0,
  views: 0,
  galleriesCount: 0,
  moviesCount: 0,
};
