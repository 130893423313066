import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LiveGirlsContentOneProvider } from './LiveGirlsContentOneProvider';
import { useSelector } from '../../redux';
import { loadCamsFunction } from '../../containers/Cams/CamsContextProvider';
import { LiveGirlsContentTwoProvider } from './LiveGirlsContentTwoProvider';
import { CamTrackingPosition } from 'src/client/constants/camTracking';

type Props = {
  camTrackingPosition: CamTrackingPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hideLiveMenu: (...args: any) => void;
  liveMenuOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showLiveMenu: (...args: any) => void;
};

const transformArrow = (ref: HTMLDivElement) => {
  if (!ref) return;

  const box = ref.getBoundingClientRect();
  const winWidth = window.innerWidth - 20;

  if (box.right > winWidth) {
    const diff = Math.abs(box.right - winWidth);
    ref.style.left = `calc(50% - ${diff}px)`; // eslint-disable-line no-param-reassign
    const arrow = ref.querySelector<HTMLDivElement>('.man-arrow');
    if (arrow) {
      arrow.style.transform = `translateX(${diff}px)`;
    }
  }
};

export const LiveGirlsPopover = (props: Props) => {
  const config = useSelector((state) => state.app.config);
  const siteUUID = useSelector((state) => state.site.UUID);
  const { cams, loaded } = useSelector((state) => state.cams);
  const dispatch = useDispatch();
  const camTrackingPosition: CamTrackingPosition = 'header';

  useEffect(() => {
    if (window.matchMedia('(max-width: 768px)').matches) return;

    if (!loaded || props.liveMenuOpen) {
      dispatch(loadCamsFunction(config.camProviders, siteUUID));
    }
  }, [dispatch, loaded, props.liveMenuOpen, config.camProviders, siteUUID]);

  if (!props.liveMenuOpen || !loaded || !cams || !cams.length) return null;

  return (
    <div
      className="live-menu-popover hidden-xs man-popover"
      onMouseEnter={props.showLiveMenu}
      onMouseLeave={props.hideLiveMenu}
      ref={transformArrow}
    >
      <div className="man-arrow" />
      {cams.length >= 2 ? <LiveGirlsContentTwoProvider camTrackingPosition={camTrackingPosition} cams={cams} /> : null}
      {cams.length === 1 ? (
        <LiveGirlsContentOneProvider camTrackingPosition={camTrackingPosition} cam={cams[0]} />
      ) : null}
    </div>
  );
};
