const SHOW = 'man-site/share-dialog/SHOW';
const HIDE = 'man-site/share-dialog/HIDE';

const initialState = {
  isOpen: false,
  link: '',
  contentType: '',
  contentID: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isOpen: true,
        link: action.link,
        contentType: action.contentType,
        contentID: action.contentID,
      };
    case HIDE:
      return {
        ...state,
        isOpen: false,
        link: '',
      };
    default: {
      return state;
    }
  }
}

export function show(link, contentType, contentID) {
  window.dataLayer.push({
    event: 'share-dialog',
    content_type: contentType,
    content_id: contentID,
  });
  return {
    type: SHOW,
    link,
    contentType,
    contentID,
  };
}

export function hide() {
  return {
    type: HIDE,
  };
}
