import { take, call, fork, select, put } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { load as loadRatingInfo } from './ratingInfo';
import { load as loadFavoriteInfo } from './favoriteInfo';
import { RESET_DURATION } from './helpers/config';
import { SORT_TYPES } from 'src/shared/constants/photographers';
import { wait } from './helpers/wait';
import { LOAD_SUCCESS as AUTH_SUCCESS } from './auth';

const LOAD = 'man-site/photographers/LOAD';
const LOAD_SAGA = 'man-site/photographers/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/photographers/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/photographers/LOAD_FAIL';
const LOAD_NAMES = 'man-site/photographers/LOAD_NAMES';
const LOAD_NAMES_SUCCESS = 'man-site/photographers/LOAD_NAMES_SUCCESS';
const LOAD_NAMES_FAIL = 'man-site/photographers/LOAD_NAMES_FAIL';

const endpoint = constructUrl(urls.get.photographers);
const lettersEndpoint = constructUrl(urls.get.nameLetters);

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  photographers: [],
  total: 0,
  lastCallParams: {},
  pageSize: 40,
  firstNameLetter: '',
  nameLetters: {
    items: [],
    loading: false,
    loaded: false,
    error: false,
    lastCallParams: {},
  },
  timestamp: new Date().getTime(),
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...state,
        loading: true,
        error: false,
        lastCallParams,
        firstNameLetter: action.firstNameLetter,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        photographers: action.result.photographers,
        total: action.result.total,
        timestamp: new Date().getTime(),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case LOAD_NAMES: {
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: true,
          loaded: false,
          error: false,
          lastCallParams: action.lastCallParams,
        },
      };
    }
    case LOAD_NAMES_SUCCESS: {
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: false,
          loaded: true,
          items: action.result,
        },
      };
    }
    case LOAD_NAMES_FAIL:
      return {
        ...state,
        nameLetters: {
          ...state.nameLetters,
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.photographers;
  let currentState = yield select(getState);
  const { lastCallParams } = currentState;

  const nameLettersLastCallParams = {
    ...(params.countries ? { countries: params.countries } : {}),
    type: 'PHOTOGRAPHER',
  };
  if (
    params.sortBy &&
    params.sortBy === 'all' &&
    !currentState.nameLetters.loading &&
    !isLoaded(currentState.nameLetters.lastCallParams, nameLettersLastCallParams)
  ) {
    const loadNameFunc = getRequestFunc(
      [LOAD_NAMES, LOAD_NAMES_SUCCESS, LOAD_NAMES_FAIL],
      (client) =>
        client.get(lettersEndpoint, {
          params: nameLettersLastCallParams,
        }),
      {
        lastCallParams: nameLettersLastCallParams,
      }
    );
    yield call(loadNameFunc);
  }

  if (params && params.sortBy === 'all') {
    currentState = yield select(getState);
  }

  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;
  if (!currentState.nameLetters.loading && (!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) {
    const sortType = SORT_TYPES.find((ele) => ele.id === params.sortBy);

    const finalParams = {
      ...params,
      ...(sortType
        ? {
            order: sortType.order,
            direction: sortType.direction,
          }
        : {}),
    };

    delete finalParams.sortBy;

    if (params.sortBy && params.sortBy === 'all') {
      finalParams.firstNameLetter = params.firstNameLetter || currentState.nameLetters.items[0].firstNameLetter;
      const filteredFirstNameLetterObject = currentState.nameLetters.items.find(
        (object) => object.firstNameLetter === finalParams.firstNameLetter
      );
      finalParams.first = filteredFirstNameLetterObject ? filteredFirstNameLetterObject.total : 0;
    }

    const loadFunc = getRequestFunc(
      [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      (client) =>
        client.get(endpoint, {
          params: finalParams,
        }),
      {
        lastCallParams: { ...params },
        mainTab: params.mainTab,
        firstNameLetter: finalParams.firstNameLetter || '',
      }
    );
    yield call(loadFunc);
  }

  yield call(wait, [AUTH_SUCCESS], (state) => state.auth.loaded);

  const newState = yield select();
  if (newState.auth.user && newState.photographers.photographers.length > 0) {
    const objectUUIDs = newState.photographers.photographers.map((item) => item.UUID);
    yield put(
      loadRatingInfo({
        objectUUIDs,
      })
    );
    yield put(
      loadFavoriteInfo({
        objectUUIDs,
      })
    );
  }

  return true;
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
