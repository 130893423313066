import { useSelector } from 'src/client/redux';
import { createAffiliateURL, getAffiliateCodeForRedirect } from '@tovia/man-app-utils/lib/helpers/affiliate';
import { SitePickerItem } from 'src/@types/SiteConfig';

export const useSitePickerList = (): SitePickerItem[] => {
  const sitePickerList = useSelector((state) => state.app.sitePickerList);
  const user = useSelector((state) => state.auth.user);
  const { accessSubdomain, siteSubdomain } = useSelector((state) => state.app);
  const currentSite = useSelector((state) => state.site);

  if (!sitePickerList?.length) {
    return [];
  }

  // The backend will return all the sites so we do some filtering on the front end
  // 1) If the site is the current site, we don't show it
  // 2) If the site is internal, we show it
  // 3) for any site that is left, return it if the user has a valid subscription
  const sitesList = sitePickerList
    .filter((site) => {
      if (site.abbreviation === currentSite.abbreviation) return false;
      if (site.isInternalSite) return true;
      if (user?.hasAnyValidSubscription) return true;
      return false;
    })
    .map((site) => {
      if (!site.isInternalSite) {
        return site;
      }

      const affiliateCode = getAffiliateCodeForRedirect(user?.firstPurchase, site.UUID);

      const fullAffiliateLink = createAffiliateURL({
        accessSubdomain,
        affiliateCode,
        siteDomain: site.domain?.toLowerCase() ?? '',
        path: user ? 'auth/man' : '',
        siteSubdomain,
      });

      const activeSubscription = user?.subscriptions.find((s) => s.abbreviation === site.abbreviation);
      const showDiscountRibbon = Boolean(user?.hasAnyValidSubscription && !activeSubscription);

      return { ...site, fullAffiliateLink, showDiscountRibbon };
    });

  return sitesList;
};
