import { CustomSuperAgentClient } from 'src/@types/superagent';
import { put, call } from 'redux-saga/effects';
import { ApiClient } from './ApiClient';

export { ApiClient };

let apiSaga: ApiClient;

export function* doRequest(
  types: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiFn: (client: CustomSuperAgentClient) => Promise<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any>,
) {
  if (!apiSaga) {
    apiSaga = new ApiClient();
  }

  let REQ_LOAD = '';
  let REQ_SUCCESS = '';
  let REQ_FAILURE = '';

  if (types) {
    [REQ_LOAD, REQ_SUCCESS, REQ_FAILURE] = types;
    yield put({ type: REQ_LOAD, ...props });
  }

  try {
    // typescript can't seem to find the proper way to use the right overload
    // with this yield function
    // @ts-ignore
    const { body } = yield call(apiFn, apiSaga);
    if (REQ_SUCCESS) {
      return yield put({ type: REQ_SUCCESS, ...props, result: body });
    }
  } catch (error) {
    if (REQ_FAILURE) {
      return yield put({ type: REQ_FAILURE, ...props, error });
    }
  }
  return false;
}

export const getRequestFunc = (
  types: [string, string, string],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiFn: (client: CustomSuperAgentClient) => Promise<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any> = {},
) => doRequest.bind(null, types, apiFn, props);
