import { JSXElementConstructor, ReactElement, ReactNode, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from '../redux/modules/helpers/useSelector';

export type Tab = {
  value: string;
  to: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: string | ReactElement<any, string | JSXElementConstructor<any>>;
  headerTitle: ReactNode;
  requiresAuth?: boolean;
  requiresPPSMovies?: boolean;
  requiresPPSGalleries?: boolean;
  className?: string;
  activeClassName?: string;
  showCamsOnTab?: boolean;

  // @TODO slowly remove
  disabled?: boolean;
};

type TabRoute = {
  path: string | string[];
  value?: string;
  breadcrumb?: Function;
};

type UseTabs = (tabs: Tab[], tabRoutes: TabRoute[]) => [Tab[], Tab | null | undefined];

export const useTabs: UseTabs = (tabs, tabRoutes) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const activeTabs = useMemo(
    () => tabs.filter((tab) => (tab.requiresAuth ? !!user : true)).filter((tab) => !tab.disabled),
    [tabs, user],
  );

  const activeTab = useMemo(() => {
    const routeTab = tabRoutes.find((route) => {
      const result = !!matchPath(location.pathname, {
        path: route.path,
        exact: true,
      });
      return result;
    });

    if (!routeTab) return null;

    return activeTabs.find((tab) => routeTab.value === tab.value);
  }, [location.pathname, tabRoutes, activeTabs]);

  return [activeTabs, activeTab];
};
