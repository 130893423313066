import superagent, { SuperAgentRequest } from 'superagent';
import { Cookies } from 'react-cookie';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import urls from 'src/shared/urls';
import { CSRF_TOKEN } from 'src/shared/constants/cookies';

type Method = 'get' | 'post' | 'put' | 'patch' | 'del';
const methods: Method[] = ['get', 'post', 'put', 'patch', 'del'];

type CustomSuperAgentRequest = (
  path: string,
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
  },
) => SuperAgentRequest;

type SuperAgentError = Error & {
  code: string;
  status: number;
};

export interface ApiClient {
  get: CustomSuperAgentRequest;
  post: CustomSuperAgentRequest;
  put: CustomSuperAgentRequest;
  patch: CustomSuperAgentRequest;
  del: CustomSuperAgentRequest;
}

export class ApiClient {
  constructor() {
    methods.forEach((method) => {
      this[method] = (path, { params, data } = {}) => {
        const request = superagent[method](path);

        const csrf = new Cookies().get(CSRF_TOKEN);

        // Set `csrf-token` header with our csrf
        if (csrf) {
          request.set('csrf-token', csrf);
        }

        if (params) {
          request.query(params);
        }

        if (data) {
          request.send(data);
        }

        return request;
      };
    });
  }
}

type UseApiClientProps = {
  url: string;
  method?: Method;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>;
  enable404Redirect?: boolean;
};

export const useApiClient = <T>(props: UseApiClientProps): [T | null, boolean, null | Error] => {
  const { params, data, url, method = 'get', enable404Redirect = false } = props;

  // useReducer?
  const [loading, setLoading] = useState(true);
  const [responseBody, setResponseBody] = useState<T | null>(null);
  const [error, setError] = useState<null | SuperAgentError>(null);
  const isComponentMounted = useRef(true);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    const request = new ApiClient()[method](url, { params, data });

    const makeRequest = async () => {
      try {
        const response = await request;
        if (isComponentMounted) {
          setResponseBody(response.body);
          setError(null);
          setLoading(false);
        }
      } catch (error) {
        if (isComponentMounted) {
          setResponseBody(null);
          setError(error);

          if (error.code !== 'ABORTED') {
            setLoading(false);
          }
        }
      }
    };

    makeRequest();

    return () => {
      isComponentMounted.current = false;
      request.abort();
    };
  }, [params, data, url, method]);

  useEffect(() => {
    if (enable404Redirect && error?.status === 404) {
      history.replace(urls.get.notFound);
    }
  }, [error, history, enable404Redirect]);

  return [responseBody, loading, error];
};
