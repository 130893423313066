import React from 'react';
import styled from 'styled-components/macro';
import AdvancedModal from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { displayModelStat } from '../../utils/converters';
import { useSelector, useSettingsSelector } from '../../redux/modules/helpers/useSelector';

type Props = {
  attributes: {
    name: string;
    eyes?: string;
    hair?: string;
    height?: number;
    weight?: number;
    breasts?: string;
    biography?: string | boolean;
    size?: string;
    pubicHair?: string;
    ethnicity: string;
  };
  coverImageUrl: string;
  show: boolean;
  onHide: () => void;
};

export default function ModelStatsModal(props: Props) {
  const measurementSystem = useSettingsSelector('measurements');
  const config = useSelector((state) => state.app.config);

  const {
    attributes: { name, eyes, hair, height, weight, breasts, size, pubicHair, ethnicity, biography },
    coverImageUrl,
  } = props;

  const showModelBiography = config.components.modelDetailsPanel.showModelBiography as boolean;
  const showBio = showModelBiography && biography !== '0';

  const attrs = [
    { label: 'Eye color', statType: 'eye', value: eyes },
    { label: 'Hair color', statType: 'hair', value: hair },
    { label: 'Height', statType: 'height', value: height },
    { label: 'Weight', statType: 'weight', value: weight },
    { label: 'Breasts', statType: 'breast', value: breasts },
    { label: 'Measurements', statType: 'measurements', value: size },
    { label: 'Shaved', statType: 'shaved', value: pubicHair },
    { label: 'Ethnicity', statType: 'ethnicity', value: ethnicity.replace(/(\w)/, (match) => match.toUpperCase()) },
  ];

  return (
    <StyledModal show={props.show} onClose={props.onHide}>
      <ModelImage data-testid="model-image" src={coverImageUrl} alt={name} className="pull-left" />
      <ModelName>{name}</ModelName>
      <div>
        {attrs
          .filter((attr) => !!attr.value)
          .map(({ label, statType, value }) => (
            <div key={statType}>
              <strong>{label}: </strong>
              <span>
                {displayModelStat({
                  statType,
                  value,
                  measurements: measurementSystem,
                })}
              </span>
            </div>
          ))}
      </div>
      {showBio && !!biography && (
        <>
          <h3>Bio</h3>
          <p>{biography}</p>
        </>
      )}
    </StyledModal>
  );
}

const ModelImage = styled.img`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    max-width: 230px;
    margin-right: 30px;
  }
`;

const StyledModal = styled(AdvancedModal)`
  padding: 30px;
  width: 600px;

  /* @TODO remove after fixed in man-ui */
  max-width: 100%;
`;

const ModelName = styled.h3`
  margin-top: 0;
`;
