import React, { useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { useCookies } from 'react-cookie';
import isArray from 'lodash/isArray';
import { useLocation } from 'react-router';
import { useSelector } from '../../redux/modules/helpers/useSelector';

export const cookieName = 'history';

export const cookieOption = {
  path: '/',
};

export const recentsListSize = 6;

export default function RecentCookiesPageInjector() {
  const [cookies, setCookie] = useCookies([cookieName]);
  const { pageTitle } = useSelector((state) => state.app);
  const location = useLocation();

  useEffect(() => {
    if (pageTitle && pageTitle !== '') {
      const historyList = (isArray(cookies[cookieName]) && cookies[cookieName]) || [];
      const historyItem = {
        text: pageTitle,
        url: location.pathname,
      };
      const itemIndex = findIndex(historyList, (recentObj) => isEqual(recentObj, historyItem));

      if (itemIndex !== -1) {
        setCookie(
          cookieName,
          [historyItem, ...historyList.slice(0, itemIndex), ...historyList.slice(itemIndex + 1, recentsListSize)],
          cookieOption
        );
      } else {
        setCookie(cookieName, [historyItem, ...historyList.slice(0, recentsListSize - 1)], cookieOption);
      }
    }
  }, [pageTitle]); // eslint-disable-line react-hooks/exhaustive-deps
  // TODO: fix array of dependencies for cookies
  // we have to violate the rules of hooks here or else you will get in an infinite loop. "cookies" and "setCookie"
  // changes each time there is a cookie set or removed, which means this useEffect will fire constantly if you have
  // either in the array of dependencies. we should probably find a better way to do this here.

  return <></>;
}
