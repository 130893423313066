import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { SortDropdown } from '@tovia/man-ui';
import ConnectedViewOptions from '../ConnectedViewOptions';
import { GalleryCards } from '../Cards/Gallery/GalleryCards';
import ModelCards from '../Cards/Model/ModelCards';
import PhotographerCards from '../Cards/Photographer/PhotographerCards';

const Div = styled.div`
  margin-bottom: 10px;

  .options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;

    .sort-dropdown {
      margin-right: 20px;
    }
  }

  .in-between {
    flex: auto;
  }

  h2 {
    font-size: 2.15rem;
    margin: 10px 0;
  }
`;

export default function ContentListSection(props) {
  const { title, sortDropdownProps, items, type, viewPeriod } = props;

  return (
    <Div className="content-list-section">
      <div className="options">
        {title && <h2>{title}</h2>}
        <div className="in-between" />
        {sortDropdownProps && <SortDropdown {...sortDropdownProps} />}
        <ConnectedViewOptions />
      </div>
      <div>
        {type === 'GALLERY' && <GalleryCards galleries={items} viewPeriod={viewPeriod} />}
        {type === 'MODEL' && <ModelCards models={items} viewPeriod={viewPeriod} />}
        {type === 'PHOTOGRAPHER' && <PhotographerCards photographers={items} viewPeriod={viewPeriod} />}
      </div>
    </Div>
  );
}

ContentListSection.propTypes = {
  title: PropTypes.string,
  sortDropdownProps: PropTypes.object,
  items: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['GALLERY', 'PHOTOGRAPHER', 'MODEL']),
  viewPeriod: PropTypes.string,
};

ContentListSection.defaultProps = {
  title: null,
  sortDropdownProps: null,
  type: 'GALLERY',
  viewPeriod: undefined,
};
