import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { withTheme } from 'styled-components/macro';

import Slider from '../../../components/sliders/Slider/Slider';
import { loadBanner } from '../../../redux/modules/updates';
import { sitePropType } from '../../../redux/modules/sites';

export class UpdatesSliderClass extends Component {
  componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    const { load, name } = this.props;

    const params = {
      name,
    };
    load(params);
  };

  render() {
    const { banners, className, contentCdnUrl, site } = this.props;

    const settings = {
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 650,
    };

    if (banners && banners.length) {
      return (
        <Slider settings={settings} className={`${className}`}>
          {banners.map((banner) => (
            <Fragment key={banner.name}>
              <NavLink to={banner.path}>
                <img
                  className="image"
                  src={`${contentCdnUrl}/${site.UUID}${banner.coverImagePath}`}
                  alt={banner.name}
                />
                <span className="text">
                  {banner.models.length ? `${banner.models.join(' & ')} in ` : ''}
                  {banner.name}
                  {banner.photographers.length ? ` by ${banner.photographers.join(' & ')}` : ''}
                </span>
              </NavLink>
            </Fragment>
          ))}
        </Slider>
      );
    }

    return null;
  }
}

UpdatesSliderClass.propTypes = {
  banners: PropTypes.array,
  contentCdnUrl: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  className: PropTypes.string,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  site: sitePropType.isRequired,
};

UpdatesSliderClass.defaultProps = {
  banners: [],
  className: '',
};

export default connect(
  (state) => ({
    banners: state.updates.banners,
    site: state.site,
    contentCdnUrl: state.app.contentCdnUrl,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        load: loadBanner,
      },
      dispatch,
    ),
)(
  withTheme(styled(UpdatesSliderClass)`
    margin: 0 -15px;
    padding: 0;

    & .previous,
    & .next {
      height: 70px;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
    }

    & .slick-prev.previous,
    & .slick-next.next {
      background-color: ${(props) => props.theme.primary2};
      color: ${(props) => props.theme.primary4};

      :hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.primary3};
      }
    }

    & .slick-prev.previous,
    & .previous {
      left: 5px;
    }

    & .slick-next.next,
    & .next {
      right: 5px;
    }

    & .image {
      width: 100%;
    }

    & .text {
      background-color: rgba(0, 0, 0, 0.75);
      bottom: 0;
      display: block;
      padding: 5px 0 5px 10px;
      position: absolute;
      width: 100%;
      color: white;
    }
  `),
);
