export type PartnerTrackingData = {
  originalCode: string;
  partnerID: number;
  siteAbbreviation: string;
  tourID: number;
  tourPriceGroupID: number;
  programID: number;
  marketingToolID: number;
  campaignID: number;
};

const splitMamTrackingCodeIntoParts = (value: string) =>
  value.match(/^([0-9]+?)\.([a-z0-9]+?)\.([0-9]+?)\.([0-9]+?)\.([0-9]+?)\.([0-9]+?)\.([0-9]+?)$/i);

const parseMamTrackingCode = (mamCookie: string): PartnerTrackingData => {
  if (!mamCookie) {
    throw Error('No mam cookie found');
  }

  let trackingData = splitMamTrackingCodeIntoParts(mamCookie);

  if (!trackingData) {
    // base64 decode
    try {
      trackingData = splitMamTrackingCodeIntoParts(atob(mamCookie));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      throw Error('Cannot decode tracking code');
    }
  }

  if (!trackingData) {
    throw Error('Invalid tracking code');
  }

  const [
    originalCode,
    partnerID,
    siteAbbreviation,
    tourID,
    tourPriceGroupID,
    programID,
    marketingToolID,
    campaignID,
  ] = trackingData;

  // retaining this sorting since this is the order of the MAM code parts as seen in the above array destructuring
  return {
    originalCode,
    partnerID: +partnerID,
    siteAbbreviation,
    tourID: +tourID,
    tourPriceGroupID: +tourPriceGroupID,
    programID: +programID,
    marketingToolID: +marketingToolID,
    campaignID: +campaignID,
  };
};

export const getPartnerTrackingData = (mamCookie: string) => {
  let partnerTrackingData: PartnerTrackingData | undefined;

  try {
    partnerTrackingData = parseMamTrackingCode(mamCookie);
  } catch {
    // do nothing, undefined will be returned below
  }

  return partnerTrackingData;
};

export const isPartner = (mamCookie: string, partnerIDs: number[] = []) => {
  const partnerTrackingData = getPartnerTrackingData(mamCookie);

  if (!partnerTrackingData) {
    return false;
  }

  const { partnerID } = partnerTrackingData;

  return partnerID > 0 && partnerIDs.includes(partnerID);
};
